import { GenericDalOperation, Entities } from './entityDalFactory.constants';
import { ILookupFilterDTO, ILookupSortingDTO } from '../interfaces/web-api';
import { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { IDeleteItem } from '../componentFunctions';
import { entityDalFactory } from '.';

/** Custom interface for Server error returing value */
export interface ServerError {
  httpStatusCode: number;
  userMessage: string;
  internalMessage: string;
  errorCode?: number;
  success?: boolean;
  message?: string;
  tips: string;
}

// la risposta 400 è diversa dalle altre
export interface ServerError400 {
  errors: { [p: string]: string[] };
  status: 400;
  title: string;
  traceId: string;
  type: string;
}

export interface ServerResponse<T> {
  data?: T;
  error?: ServerError;
}

export interface ServerResponse<T> {
  data?: T;
  error?: ServerError;
}

export enum LookupFilterOperation {
  Contains,
  StartsWith,
  EndsWith,
  Equals,
  Less,
  LessOrEquals,
  More,
  MoreOrEquals,
}

export enum LookupSortingDirection {
  Asc,
  Desc,
}

/** LookupRepositoryDTO not exported from swagger schema definition */
export interface ILookupRepositoryDTO {
  pageNumber: number;
  pageSize: number;
  filters?: ILookupFilterDTO[];
  sorting?: ILookupSortingDTO[];
  complexExpresionFilters?: string;
}

/** LookupResultDTO not exported from swagger schema definition */
export interface ILookupResultDTO<T> {
  data?: T[] | null;
  totalRecords: number; // int64
  totalPages: number; // int64
}

export type RequestConfig = AxiosRequestConfig;

// Dal Interface for base single operation

/** Base Interface for DAL object */
export interface IDalBaseEntity {
  /** Entity which DAL operates, is used to build the end point URL */
  entityName: Entities;
  /** Used to block call for manual debounce */
  getCancelToken(): IDalCancelToken;
}

/** Interface for DAL object which enables GET operation with filters */
export interface IDalReadFilter<T> extends IDalBaseEntity {
  /** Crud GET operation with filter query string */
  get: <TRes = ILookupResultDTO<T>>(headersFilters?: ILookupRepositoryDTO, requestOptions?: RequestConfig) => Promise<ServerResponse<TRes>>;
}

/** Interface for DAL object which enables GET operation on single ID of object */
export interface IDalReadById<T> extends IDalBaseEntity {
  /** Crud GET operation with single ID url */
  getById: (id: number | string, requestOptions?: RequestConfig) => Promise<ServerResponse<T>>;
}

/** Interface for DAL object which enables POST operation */
export interface IDalC<T> extends IDalBaseEntity {
  /** Crud POST operation with single item */
  create: (item: T, requestOptions?: RequestConfig) => Promise<T | ServerError>;
}

/** Interface for DAL object which enables PUT operation */
export interface IDalU<T> extends IDalBaseEntity {
  /** Crud PUT operation with single item */
  update: (id: number | string, item: T, requestOptions?: RequestConfig) => Promise<boolean | ServerError>;
}

/** Interface for DAL object which enables DELETE operation */
export interface IDalD extends IDalBaseEntity {
  /** Crud DELETE operation with single item */
  del: (id: number | string, hash: number, requestOptions?: RequestConfig) => Promise<boolean | ServerError>;
  /** Crud DELETE operation for array of items */
  dels: (rows: IDeleteItem[], requestOptions?: RequestConfig) => Promise<boolean | ServerError>;
}

// export interface IDalDel extends IDalBaseEntity {
//   dels1: (rows: deleteItem[], requestOptions?: RequestConfig) => Promise<number | ServerError>;
// }

// Dal interface composition
export interface IDalR<T> extends IDalReadFilter<T>, IDalReadById<T> {}

export interface IDalCU<T> extends IDalC<T>, IDalU<T> {}

export interface IDalCRU<T> extends IDalR<T>, IDalCU<T> {}

export interface IDalCRUD<T> extends IDalCRU<T>, IDalD {}

// export interface IDalCRUD1<T> extends IDalDel {}

/** Input options for configuring the DAL Factory */
export interface IDalFactoryOptions {
  /** Enums which specifies which operation enable to the created DAL object */
  enableOperation: GenericDalOperation;
  /** Name of the entity which the DAL should operate */
  entityName: Entities | string;
  /** WebAPI baseURL*/
  apiType?: string;
}

export interface IDalCancelToken extends CancelTokenSource {}

export const entityDalFactoryR = <T>(entityName: Entities) =>
  entityDalFactory<T, IDalR<T>>({
    enableOperation: GenericDalOperation.READ,
    entityName,
  });
