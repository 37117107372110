import { IReduxAction } from '../reduxStore.interfaces';
import { AuthConstants, initialState } from '../costants/auth.constants';
import { IAuthActionsPayload, IAuthState } from '../interfaces/auth.interfaces';

type Actions = keyof typeof AuthConstants;

export const authReducer = (state: IAuthState = initialState, action: IReduxAction<Actions, IAuthActionsPayload>): IAuthState => {
  switch (action.type) {
    case AuthConstants.Login:
      let sedeSel = state.selectedSede;
      if (sedeSel !== undefined) {
        //se c'è una sede selezionata devo vedere se ancora esiste
        if (action.payload.sediList.find((s) => s.az02Id === sedeSel?.az02Id) === undefined) {
          sedeSel = undefined;
        }
      }
      if (!sedeSel) {
        // se non avevo sede selzionata oppure l'ho tolta prendo la prima nella lista
        sedeSel = action.payload.sediList.length > 0 ? { ...action.payload.sediList[0] } : undefined;
      }

      return {
        ...state,
        ...action.payload,
        selectedSede: sedeSel,
      };

    case AuthConstants.SendRequest:
      return { ...state, requestStatus: { isError: false, isFinish: false, isLoading: true } };
    case AuthConstants.RequestOk:
      return { ...state, requestStatus: { ...state.requestStatus, isFinish: true, isLoading: false } };
    case AuthConstants.RequestKO:
      return { ...state, requestStatus: { isError: true, isFinish: true, isLoading: false } };
    case AuthConstants.SetCurrentSede:
      const { selectedSede } = action.payload;
      return { ...state, selectedSede };
    case AuthConstants.DisableSwitchSede:
      const { disableSwitchSede } = action.payload;
      return { ...state, disableSwitchSede };
    case AuthConstants.SetCurrentCompany:
      const { selectedCompany } = action.payload;
      return { ...state, selectedCompany };
    case AuthConstants.Logout:
      return { ...initialState };
    default:
      return { ...state };
  }
};
