import Axios, { AxiosError } from 'axios';
import {
  IDalFactoryOptions,
  ServerError,
  ILookupRepositoryDTO,
  IDalBaseEntity,
  ServerResponse,
  ILookupResultDTO,
  RequestConfig,
  ServerError400,
} from './entityDalFactory.interfaces';
import { webApiBaseUrl, webApiHeaderFilterKey } from '../constants';
import { GenericDalOperation, HTTPStatusCode, httpRequestMethod } from './entityDalFactory.constants';
import { IDeleteItem } from '../componentFunctions';

/**
 * Base url builder factory, return url string with given web api entity
 * @param entityName Name of the entity which will form web api end point url
 * @returns url string for web api call
 */
export const urlBuilderFactory = (apiType: string) => (entityName: string) => {
  return `${apiType}/${entityName}`;
};

/**
 * catchErrorClbk
 * catch error callback used inside the method of DAL Object created inside
 * the Dal Factory
 * @param error
 */
export const catchErrorClbk = (error: any): ServerError => {
  const err: AxiosError<ServerError | ServerError400> = error;
  if (err && err.response && err.response.data) {
    if (err.response.status === 400) {
      const err400 = err.response.data as ServerError400;
      const errori = [];
      let title = err400.title;
      if (err400.errors) {
        for (const key in err400.errors) {
          if (Object.prototype.hasOwnProperty.call(err400.errors, key)) {
            const element = err400.errors[key];
            errori.push(element.join(' - '));
          }
        }
      } else {
        const stringa = err.response.data as unknown as string;
        if (stringa && stringa.length) {
          title = stringa;
        }
      }
      return {
        httpStatusCode: 400,
        userMessage: title,
        internalMessage: errori.join(' '),
        tips: err400.type,
      };
    }

    if (err.response.status === 500) {
      return {
        httpStatusCode: 500,
        userMessage: err.response.data as unknown as string,
        internalMessage: '',
        tips: '',
      };
    }

    const e = err.response.data as ServerError;
    return { ...e, httpStatusCode: err.response.status };
  }

  throw err;
};

/**
 * Utility function which return Axios function to call from the
 * requested Http method to use (GET-POST-PUT-etc...)
 * @param httpMethod Requested Http method
 * @returns requested axios function (post - get - put - etc...)
 */
export const getAxiosMethod = (httpMethod: httpRequestMethod) => {
  switch (httpMethod) {
    case httpRequestMethod.Post:
      return Axios.post;
    case httpRequestMethod.Get:
      return Axios.get;
    case httpRequestMethod.Patch:
      return Axios.patch;
    case httpRequestMethod.Delete:
      return Axios.delete;
    case httpRequestMethod.Put:
      return Axios.put;
  }
};

/**
 * Factory function which creates full CRUD DAL for accessing web api
 * CRUD method on a specified entity.
 * @param options specifies the entity for the DAL and enable the dal CRUD operation
 * @returns Dal Object for accessing backend CRUD web api
 */
export const entityDalFactory = <T, TOut extends IDalBaseEntity>(options: IDalFactoryOptions): TOut => {
  const { entityName, apiType } = options;
  const dal: any = { entityName };

  /** Url Builder function */
  const urlBuilder = urlBuilderFactory(apiType || webApiBaseUrl);

  /** Get implementation */
  const get = async <TRes = ILookupResultDTO<T>>(
    filters?: ILookupRepositoryDTO,
    requestOptions?: RequestConfig
  ): Promise<ServerResponse<TRes>> => {
    try {
      const url = `${urlBuilder(entityName)}`;
      const { headers, ...restOptions } = requestOptions || {};

      const httpHeaders = headers
        ? {
            headers: {
              [webApiHeaderFilterKey]: filters && JSON.stringify(filters),
              ...headers,
            },
          }
        : {
            headers: {
              [webApiHeaderFilterKey]: filters && JSON.stringify(filters),
            },
          };

      const optionsWithFilters = restOptions
        ? {
            ...httpHeaders,
            ...restOptions,
          }
        : {
            ...httpHeaders,
          };

      const result = await Axios.get<TRes>(url, optionsWithFilters);
      return { data: result.data };
    } catch (error) {
      return { error: catchErrorClbk(error) };
    }
  };

  if (!!(options.enableOperation & GenericDalOperation.READ_FILTER)) dal.get = get;

  /** Get By ID implementation */
  const getById = async (id: number | string, requestOptions?: RequestConfig): Promise<ServerResponse<T>> => {
    try {
      const result = await Axios.get<T>(`${urlBuilder(entityName)}${id !== '' ? `/${id}` : ''}`, requestOptions);
      return { data: result.data };
    } catch (error) {
      return { error: catchErrorClbk(error) };
    }
  };

  if (!!(options.enableOperation & GenericDalOperation.READ_BY_ID)) dal.getById = getById;

  /** Create new entity implementation */
  const create = async (item: T, requestOptions?: RequestConfig): Promise<T | ServerError> => {
    try {
      const result = await Axios.post<T>(`${urlBuilder(entityName)}`, item, requestOptions);
      return result.data;
    } catch (error) {
      return catchErrorClbk(error);
    }
  };

  if (!!(options.enableOperation & GenericDalOperation.CREATE)) dal.create = create;

  /** Update entity implementation */
  const update = async (id: number | string, item: T, requestOptions?: RequestConfig): Promise<boolean | ServerError> => {
    try {
      const result = await Axios.put(`${urlBuilder(entityName)}${id !== '' ? `/${id}` : ''}`, item, requestOptions);
      return result.status === HTTPStatusCode.NoContent;
    } catch (error) {
      return catchErrorClbk(error);
    }
  };

  if (!!(options.enableOperation & GenericDalOperation.UPDATE)) dal.update = update;

  /** Delete single entity implementation */
  const del = async (id: number | string, hash: number, requestOptions?: RequestConfig): Promise<boolean | ServerError> => {
    try {
      const result = await Axios.delete(`${urlBuilder(entityName)}/${id}/${hash}`, requestOptions);
      return result.status === HTTPStatusCode.NoContent;
    } catch (error) {
      return catchErrorClbk(error);
    }
  };

  /** Multiple delete entity implementation */
  const dels = async (data: IDeleteItem[], requestOptions?: RequestConfig): Promise<boolean | ServerError> => {
    try {
      const { data: requestData, ...restOpt } = requestOptions || {};
      const result = await Axios.delete(`${urlBuilder(entityName)}/`, {
        data: data.map((r) => ({ id: r.id.toString(), hash: r.hash })),
        ...restOpt,
      });
      return result.status === HTTPStatusCode.NoContent;
    } catch (error) {
      return catchErrorClbk(error);
    }
  };

  if (!!(options.enableOperation & GenericDalOperation.DELETE)) {
    dal.del = del;
    dal.dels = dels;
  }

  return { ...dal };
};
