import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import itIT from 'antd/lib/locale-provider/it_IT';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ReduxStoreSingleton } from './common/redux/reduxStore';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';

const renderReactDom = (locale: any, persistorDom: any, storeProvider: any) => {
  ReactDOM.render(
    <Provider store={storeProvider}>
      <ConfigProvider locale={locale}>
        <PersistGate loading={null} persistor={persistorDom}>
          <App />
        </PersistGate>
      </ConfigProvider>
    </Provider>,
    document.getElementById('root')
  );
};

ReduxStoreSingleton.getInstance().then(({ store, persistor }) => {
  renderReactDom(itIT, persistor, store);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
