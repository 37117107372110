import { Alert, Popover } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/ts-logo.png';
import { authProvider, ILoginUserToken } from '../../common/authProvider';
import { LoginDTO, Vw01AziendaanagraficaDTO } from '../../common/interfaces/web-api';
import { ILoginFormProps } from './Login.interfaces';
import { UserNameInputComponent, PasswordInputComponent } from './loginForm.fields';
import { loginUIConstants } from './Login.constants';
import { LoginDal } from './Login.dal';
import { LoginHistory } from '../../common/interfaces';
import Vpgdpr from '@agrocloud/react-vapor-component/vpgdpr';
import { Vpbutton } from '@agrocloud/react-vapor-component/vpbutton';
import { Vploading } from '@agrocloud/react-vapor-component/vploading';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { authActions } from '../../common/redux';

const initialFormValues: ILoginFormProps = { userName: '', password: '' };

export const Login: React.FC = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [isError, setErrorFlag] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const history = useHistory<LoginHistory>();
  const dispatch = useDispatch();

  const routeChange = () => {
    window.location.href = `${process.env.REACT_APP_TSID_ENDPOINT}/Account/Register`;
  };

  const onFormSubmit = async (values: ILoginFormProps): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    const data: LoginDTO = {
      userName: values.userName,
      email: values.userName,
      password: values.password,
      token: '',
      refreshToken: '',
    };

    try {
      const loginResult = await LoginDal.login(data);
      authProvider.login(loginResult as ILoginUserToken);
      const companiesResult = await LoginDal.getUserCompanies();
      if (companiesResult.length > 1) history.push('/companyswitch', { userCompanies: companiesResult });
      else {
        dispatch(authActions.setCurrentTenant(companiesResult[0].companyRegistryData as Vw01AziendaanagraficaDTO));
        history.push('/', { fromLogin: true });
      }
    } catch (err) {
      const e = err as AxiosError;
      setFetching(false);
      setErrorFlag(true);
      if (e.response?.status === 401) {
        setErrorMsg('E-mail o password errati');
        return;
      }
      setErrorMsg('Errore imprevisto');
      console.error(e.message);
    }
  };

  return (
    <>
      <div className="login-container">
        <Formik initialValues={initialFormValues} onSubmit={onFormSubmit}>
          {() => (
            <div className="panel-login md-card-5">
              <div className="panel-head">
                <img className="login-logo" src={logo} alt="logo" />
              </div>
              <div className="panel-body">
                <div className="tsid-info">
                  <span>
                    Entra con il tuo TeamSystem ID.&nbsp;
                    <Popover style={{ maxWidth: '200px' }} content={loginUIConstants.teamsystemIDText}>
                      <span style={{ color: 'blue', textDecoration: 'underline' }}>Che cos'&egrave;?</span>
                    </Popover>
                  </span>
                </div>
                <Form className="login-form">
                  <Field name="userName" label="E-mail" component={UserNameInputComponent} placeholder="n.cognome@esempio.com" />
                  <div className="divider" />
                  <Field name="password" component={PasswordInputComponent} placeholder="**********" />
                  {isError && <Alert message={errorMsg} type="error" showIcon />}
                  <div className="divider" />
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <a href="https://myprofile.teamsystem.com/Account/ForgotPassword">Password dimenticata?</a>
                  </div>

                  <div className="login-buttons-group">
                    <Vpbutton type="secondary" onClick={routeChange} htmlType="button">
                      Registrati
                    </Vpbutton>
                    <Vpbutton type="primary" htmlType="submit">
                      Accedi
                    </Vpbutton>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
        <Vpgdpr
          gdprLink="https://gdpr.teamsystem.io/info/polyedro/istruzioni.html"
          cookieLink="https://polyedro.teamsystem.com/www/_static/informativa_cookies.html"
        />
      </div>
      {fetching && <Vploading />}
    </>
  );
};
