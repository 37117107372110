// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../webfonts/fa-regular-400.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../webfonts/fa-regular-400.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "/*!\n * Font Awesome Pro 5.10.2 by @fontawesome - https://fontawesome.com\n * License - https://fontawesome.com/license (Commercial License)\n */@font-face{font-family: 'Font Awesome 5 Pro';font-style: normal;font-weight: 400;src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');}\n\n.far{font-family: 'Font Awesome 5 Pro';font-weight: 400;}\n", ""]);
// Exports
module.exports = exports;
