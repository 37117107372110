import { Entities } from '../../entityDalFactory';
import { IGridState } from '../interfaces/grid.interfaces';

export const GridConstants = (ent: Entities) => ({
  GridReqData: `${ent}_GridReqData`,
  GridSetData: `${ent}_GridSetData`,
  SetFilters: `${ent}_GridSetFilters`,
  SetHiddenColumns: `${ent}_GridSetHiddenColumns`,
});

export const initialGridState = <T>(): IGridState<T> => ({
  filters: {
    pageIndex: 1,
    pageSize: 30,
    filters: [],
    sortBy: [],
  },
  hiddenColumns: [],
  rows: [],
});
