// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\CampoNonAllineatoDTO.cs
export interface CampoNonAllineatoDTO {
  campo: string;
  valoreMipaaf: any;
  valoreLocale: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvInputDTO.cs
export interface MvvInputDTO {
  id: string;
  token: string;
  codiceIcqrf: string;
  codIcqrfDest: string;
  codIcqrfSped: string;
  idDocOri: string;
  dataDocOri?: string;
  numDocOri: string;
  sezDocOri: string;
  tipo?: number;
  marcaTemp: string;
  numMVV: string;
  stato: string;
  pdf: string;
  dataValidazione?: string;
  dataPresentazione: string;
  xmlMVVSiRPVInput: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\CatalogoValidateError.cs
export interface CatalogoValidateError {
  field: string;
  message: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvInputEmergencyDTO.cs
export interface MvvInputEmergencyDTO extends MvvInputDTO {
  flagPecEmerg?: boolean;
  dataTraspMvvCartaceo?: string;
  numMvvCartaceo: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvInputReceivedDTO.cs
export interface MvvInputReceivedDTO {
  filter: any; // LookUpFilter;
  tenantId: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvInputStateDTO.cs
export interface MvvInputStateDTO {
  id: string;
  token: string;
  codiceIcqrf: string;
  numDocOri: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvOutputDTO.cs
export interface MvvOutputDTO {
  id: string;
  stato: string;
  marcaTemp: string;
  numMVV: string;
  pdf: string;
  codiceErrore: string;
  descrizioneErrore: string;
  xmlMVVSiRPVOutput: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvOutputReceivedDTO.cs
export interface MvvOutputReceivedDTO {
  id: string;
  stato: string;
  dataValidazione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvOutputStateDTO.cs
export interface MvvOutputStateDTO {
  id: string;
  stato: string;
  dataValidazione: string;
  marcaTemp: string;
  numMVV: string;
  pdf: string;
  codiceErrore: string;
  descrizioneErrore: string;
  noteRicezione: string;
  dataRicezione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvOutuptReplyDTO.cs
export interface MvvOutuptReplyInputDTO {
  mvvId: string;
  status: ReplyStatus;
  note: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvOutuptReplyDTO.cs
export interface MvvOutuptReplyResultDTO {
  mvv: MVVBase<VisDettMVVDestSiRPVOutput>;
  esito: string;
  esitoDesc: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\MvvOutuptReplyDTO.cs
export enum ReplyStatus {
  Accettato = 0,
  Respinto = 1,
  Accettato_con_revisione_quantitativi = 2,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\PubblishMipaafIdTrasmissioneDTO.cs
export interface PubblishMipaafIdTrasmissioneDTO {
  idTrasmissione: string;
  sedeIcqrf: string;
  errori: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\PublishResultCataloghiDTO.cs
export interface PublishResultCataloghiDTO {
  codice: string;
  messaggio: string;
  vi33Id: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\PublishResultiDTO.cs
export interface PublishResultTrasmissioneDTO {
  idTrasmissione: string;
  status: StatoPublishMipaff;
  esitoTrasmissione: WsEsitoTrasmissioneDTO;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\ScaricaMvvResultDTO.cs
export interface ScaricaMvvResultDTO {
  mvvScaricati: MVVBase<VisDettMVVDestSiRPVOutput>[];
  mvvAggiornati: MVVBase<VisDettMVVDestSiRPVOutput>[];
  mvvIgnorati: MVVBase<VisDettMVVDestSiRPVOutput>[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaCataloghiResultDTO.cs
export interface SincronizzaCataloghiResultDTO {
  cataloghiNonAllineati: InfoCatalogoNonAllineatoDTO[];
  cataloghiSoloMipaaf: InfoCatalogoAllineatoDTO[];
  cataloghiAllineati: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaCataloghiResultDTO.cs
export interface InfoCatalogoNonAllineatoDTO {
  vi33Id: number;
  codiceProdotto: WSCodiceProdottoDTO;
  catalogo: WsProdottoCatalogoDTO;
  campiNonAllineati: CampoNonAllineatoDTO[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaCataloghiResultDTO.cs
export interface InfoCatalogoAllineatoDTO {
  codiceProdotto: WSCodiceProdottoDTO;
  catalogo: WsProdottoCatalogoDTO;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaGiacenzaCataloghiResultDTO.cs
export interface SincronizzaGiacenzaCataloghiResultDTO {
  giacenze: WsGiacenzaProdottoDTO[];
  cataloghiNonPresenti: WSCodiceProdottoDTO[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaMvvResultDTO.cs
export interface SincronizzaMvvResultDTO {
  mvvAllineati: MVVBase<MVVSiRPVInput>[];
  mvvNonAllineati: MVVBase<MVVSiRPVInput>[];
  mvvSoloCloud: MVVBase<MVVSiRPVInput>[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaSoggettiResultDTO.cs
export interface SincronizzaSoggettiResultDTO {
  soggettiNonAllineati: InfoSoggettoNonAllineatoDTO[];
  soggettiSoloMipaaf: WsSoggettoDTO[];
  numSoggettiAllineati: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaSoggettiResultDTO.cs
export interface InfoSoggettoNonAllineatoDTO {
  soggetto: WsSoggettoDTO;
  campiNonAllineati: CampoNonAllineatoDTO[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaVasiResultDTO.cs
export interface SincronizzaVasiResultDTO {
  vasiNonAllineati: InfoVasoNonAllineatoDTO[];
  vasiSoloMipaaf: WsVasoVinarioDTO[];
  vasiAllineati: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaVasiResultDTO.cs
export interface InfoVasoNonAllineatoDTO {
  vaso: WsVasoVinarioDTO;
  campiNonAllineati: CampoNonAllineatoDTO[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaVigneResultDTO.cs
export interface SincronizzaVigneResultDTO {
  codiceFlusso: string;
  vigneNonAllineati: InfoVignaNonAllineatoDTO[];
  vigneSoloMipaaf: WsVignaDTO[];
  vigneAllineati: WsVignaDTO[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\SincronizzaVigneResultDTO.cs
export interface InfoVignaNonAllineatoDTO {
  vigna: WsVignaDTO;
  campiNonAllineati: CampoNonAllineatoDTO[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\Types\StatoMvvMipaff.cs
export enum StatoMvvMipaff {
  MvvSoloCloud = 0,
  MvvAllineati = 1,
  MvvNonAllineati = 2,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\Flussi\Types\StatoPublishMipaff.cs
export enum StatoPublishMipaff {
  TimeOut = 0,
  Error = 1,
  Ok = 2,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\Enum.cs
export enum InvioMipaaf {
  NonInviato = 0,
  Attesa = 10,
  Inviato = 20,
  NonValidato = 30,
}

export enum TipoVasi {
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  E = 5,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\ProdSincrRiRPVInputDTO.cs
export interface ProdSincrRiRPVInputDTO {
  codOper: CUAA;
  codiceIcqrf: string;
  tipoRichiesta: TipoRichiesta;
  prodCatalogo: ProdSincrSiRPVInputProdCatalogoDTO;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\ProdSincrSiRPVOutputDto.cs
export interface ProdSincrSiRPVOutputDTO {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  prodottoEsito: ProdottoEsito;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsDettMvvDestDTO.cs
export interface WsDettMvvDestDTO {
  dettaglioSped: DettaglioSoggettoMVVRicevuto;
  lspedIndirizzo: Indirizzo;
  dettaglioDest: DettaglioSoggettoMVVRicevuto;
  dettaglioLDest: DettaglioSoggettoMVVRicevuto;
  ldestCodICRF: string;
  dettaglioVend: DettaglioSoggettoMVVRicevuto_1;
  dettaglioAcq: DettaglioSoggettoMVVRicevuto_1;
  dettaglioTras: DettaglioSoggettoMVVRicevuto_1;
  tipmeCod: string;
  mezzoTarga: string;
  rimorchioTarga: string;
  condNome: string;
  condCognome: string;
  titrCod: string;
  altreInfo: string;
  noteAltreAttes: string;
  dataTrasp: string;
  oraTrasp: DettMVVDestOraTrasp;
  minutiTrasp: DettMVVDestMinutiTrasp;
  prodottiMVV: ProdMVVRicevuto[];
  noteUlteriori: string;
  emailDestinatario: string;
  emailCondTras: string;
  cellCondTras: PrefCellCondTras;
  certificatoOrigine: Certificazione;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsEsitoTrasmissioneDTO.cs
export interface WsEsitoTrasmissioneDTO {
  idTrasmissione: string;
  esitoCodice: string;
  esitoMessaggio: string;
  soggettiOutput: WsSoggettoEsitoDTO[];
  vigneOutput: WsVignaEsitoDTO[];
  vasiVinariOutput: WsVasoVinarioEsitoDTO[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsGiacenzaProdottoDTO.cs
export interface WsGiacenzaProdottoDTO {
  codiceProdotto: WSCodiceProdottoDTO;
  codCommittente: string;
  datagetgiac: string;
  unMis: string;
  qtaGiacenza: number;
  committenteDescrizione: string;
  prodottoDescrizione: string;
  vi33aId?: number;
  vi33aIdWi33?: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsListaDopIgpDTO.cs
export interface WsListaDopIgpDTO {
  codDopIgp: string;
  descrizione: string;
  tipologiaVino: string;
  codPregresso: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsListaEbacchusDTO.cs
export interface WsListaEbacchusDTO {
  codice: string;
  descrPaese: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsListaMenzioneDTO.cs
export interface WsListaMenzioneDTO {
  codice: string;
  descrizione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsListaMvvDestDTO.cs
export interface WsListaMvvDestDTO {
  numMVV: string;
  cUAASped: CUAA;
  dataVal: string;
  statoVal: ListaMVVDestStatoVal;
  codIcqrfSped: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsListaMvvDTO.cs
export interface WsListaMvvDTO {
  numMVV: string;
  dataVal: string;
  dataAnn: string;
  statoVal: ListaMVVStatoVal;
  codIcqrfDest: string;
  dataValSpecified: boolean;
  dataAnnSpecified: boolean;
  cUAADest: CUAA;
  identLocaleMVV: string;
  marcaTemp: string;
  noteRicez: string;
  dataRicez: string;
  dataRicezSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsListaSottozonaDTO.cs
export interface WsListaSottozonaDTO {
  codice: string;
  descrizione: string;
  descrVinoZona: string;
  tipoZona: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsListaVarietaDTO.cs
export interface WsListaVarietaDTO {
  codice: string;
  descrizione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsListaZonaViticolaDTO.cs
export interface WsListaZonaViticolaDTO {
  codice: string;
  descrizione: string;
  codead: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsMvvDTO.cs
export interface WsMvvDTO {
  identLocaleMVV: string;
  certificatoOrigine: Certificazione;
  cellCondTras: PrefCellCondTras;
  emailCondTras: string;
  emailDestinatario: string;
  noteUlteriori: string;
  prodottiMVV: ProdMVV[];
  minutiTrasp: MVVSiRPVInputMinutiTrasp;
  oraTrasp: MVVSiRPVInputOraTrasp;
  dataTrasp: string;
  noteAltreAttes: string;
  altreInfo: string;
  titrCod: string;
  condCognome: string;
  condNome: string;
  rimorchioTarga: string;
  mezzoTarga: string;
  tipmeCod: string;
  trasportatore: MVVSiRPVInputTrasportatore;
  acquirente: MVVSiRPVInputAcquirente;
  venditore: MVVSiRPVInputVenditore;
  ldestCodICRF: string;
  luogoDestinatario: MVVSiRPVInputLuogoDestinatario;
  destinatario: MVVSiRPVInputDestinatario;
  flagArt29Specified: boolean;
  flagArt29: MVVSiRPVInputFlagArt29;
  flagArt33Specified: boolean;
  flagArt33: MVVSiRPVInputFlagArt33;
  lspedIndirizzo: IndirizzoLsped;
  codIcqrfSped: string;
  codOper: CUAA;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsMvvOutputDTO.cs
export interface WsMvvOutputDTO {
  codOper: CUAA;
  esito: Response;
  numMVV: string;
  marcaTemp: string;
  filePdf: number[];
  isSuccess: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsOutputDettaglioOperazioneDTO .cs
export interface WsOutputDettaglioOperazioneDTO {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsPrnMVVSiRPVOutputDTO .cs
export interface WsPrnMVVSiRPVOutputDTO {
  codOper: CUAA;
  esito: Response;
  filePdf: number[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsProdottoCatalogoDTO.cs
export interface WsProdottoCatalogoDTO {
  codPrimario: string;
  codSecondario: string;
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: WSPaesiProvenienzaDTO[];
  codZonaViticola: string;
  varieta: WSCodValorePercDTO[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: WSMenzioniDTO[];
  biologico: string;
  praticheEnologiche: WSPraticheEnologicheDTO[];
  codPartita: string;
  annata: WSTipoAnnataDTO;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsProdottoCatalogoDTO.cs
export interface WSCodiceProdottoDTO {
  codPrimario: string;
  codSecondario: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsProdottoCatalogoDTO.cs
export interface WSVisProdSiRPVOutputProdCatalogoDTO {
  codiceProdotto: WSCodiceProdottoDTO;
  designazione: WsProdottoCatalogoDTO;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsProdottoCatalogoDTO.cs
export interface ProdSincrSiRPVInputProdCatalogoDTO extends WSVisProdSiRPVOutputProdCatalogoDTO {}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsProdottoCatalogoDTO.cs
export interface WSPaesiProvenienzaDTO {
  codice: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsProdottoCatalogoDTO.cs
export interface WSCodValorePercDTO {
  codice: string;
  percentuale: number;
  percentualeSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsProdottoCatalogoDTO.cs
export interface WSMenzioniDTO {
  codice: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsProdottoCatalogoDTO.cs
export interface WSPraticheEnologicheDTO {
  codice: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsProdottoCatalogoDTO.cs
export interface WSTipoAnnataDTO {
  codice: string;
  percentuale: number;
  percentualeSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsSoggettoDTO.cs
export interface WsSoggettoDTO {
  codiceSoggetto: string;
  codiceCUAA: CUAA;
  tipoSoggetto: SoggettoTipoSoggetto;
  nome: string;
  cognome: string;
  ragioneSociale: string;
  indirizzoSede: Indirizzo;
  flagDest: number;
  flagDestSpecified: boolean;
  codiceIcqrfSogg: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsSoggettoEsitoDTO.cs
export interface WsSoggettoEsitoDTO {
  codiceSoggetto: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsVasoVinarioDTO.cs
export interface WsVasoVinarioDTO {
  codVaso: string;
  tipoVaso: string;
  descrizione: string;
  volume: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsVasoVinarioEsitoDTO.cs
export interface WsVasoVinarioEsitoDTO {
  codVaso: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsVignaDTO.cs
export interface WsVignaDTO {
  codVigna: string;
  descrizione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\WsMipaaf\WsVignaEsitoDTO.cs
export interface WsVignaEsitoDTO {
  codVigna: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface wsRegVinoInterService {}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SOAPAutenticazione {
  username: string;
  password: string;
  nomeServizio: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DettaglioProdAttoDiventare {
  categoriaProdMonte: string;
  classificazione: string;
  codDeno4: string;
  varieta: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DichAnnullaOutput {
  annoCampagna: number;
  numeroDichiarazione: number;
  dataAnnullamento: string;
  dataAnnullamentoSpecified: boolean;
  filePdf: number[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Periodo {
  dataInizio: string;
  dataFine: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProtocolloRicerca {
  codAoo: string;
  numProtocollo: string;
  annoProtocollo: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Protocollo {
  dataProtocollo: string;
  numProtocollo: string;
  filePdfComunicazione: number[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoAttoDiventareArricchimento {
  codCategoria: string;
  codClassificazione: string;
  codDopIgp: string;
  varieta: Cod_Valore;
  qta: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Cod_Valore {
  codice: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ArricchimentoDesignProdMonte {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AardDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codPartita: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PaesiProvenienza {
  codice: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Cod_ValorePerc {
  codice: string;
  percentuale: number;
  percentualeSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Menzioni {
  codice: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PraticheEnologiche {
  codice: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TipoAnnata {
  codice: number;
  percentuale: number;
  percentualeSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoARMC1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  provenienza: string;
  origineUve: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TipoAnnataCodice {
  codice: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoMonteArricchimento {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CodiceProdotto {
  codPrimario: string;
  codSecondario: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MetodoArricchimento {
  item: string;
  itemElementName: ItemChoiceType3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PresaSpuma {
  item: any;
  itemElementName: ItemChoiceType2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AggiuntaMcrl {
  aggiuntaMcrlHl: number;
  aggiuntaMcrlHlSpecified: boolean;
  aggiuntaMcrlTitAlcol: number;
  aggiuntaMcrlTitAlcolSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GradoFinaleMcrl {
  gradoFinaleMcrlTitAlcol: number;
  gradoFinaleMcrlTitAlcolSpecified: boolean;
  gradoFinaleGrado: number;
  gradoFinaleGradoSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Arricchimento {
  item: any;
  itemElementName: ItemChoiceType1;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdottoSpum5 {
  prodotto: SpabProdottoSpum5Prodotto;
  codRecipiente: CodRecipiente[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdottoSpum5Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabDesignProd5 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CodRecipiente {
  codice: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdottoSpum1 {
  prodotto: SpabProdottoSpum1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdottoSpum1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Stabilimento {
  codiceIcqrf: string;
  indirizzo: string;
  cap: string;
  provincia: string;
  comune: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaZonaViticola {
  codice: string;
  descrizione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaMenzione {
  codice: string;
  descrizione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaSottozona {
  codice: string;
  descrizione: string;
  descrVinoZona: string;
  tipoZona: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaVarieta {
  codice: string;
  descrizione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaEbacchus {
  codice: string;
  descrPaese: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaPreCasd {
  cuaaForn: CUAA;
  codiRegiForn: string;
  codiceAllegato: string;
  codiceVino: string;
  quantitaUva: number;
  codiceColore: ListaPreCasdCodiceColore;
  annoCampagna: number;
  cuaaDest: CUAA;
  codiRegiDest: string;
  codIcqrf: string;
  dataInvi: string;
  dataBloc: string;
  dataAgg: string;
  codClassificazione: string;
  codCategoria: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CUAA {
  item: string;
  itemElementName: ItemChoiceType;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TrasportatoreElimina {
  codiceTrasportatore: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VignaElimina {
  codVigna: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VasoElimina {
  codVaso: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SoggettoElimina {
  codiceSoggetto: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface QtaDichGiacenzaSoloTotale {
  totale: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface QtaDichGiacenza {
  rossoRosato: number;
  bianco: number;
  totale: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdElimina {
  codiceProdotto: CodiceProdotto;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PraticaEno {
  codPraticaEno: string;
  percentuale: number;
  percentualeSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface OperElimina {
  dataOperazione: string;
  numOperazione: number;
  codOperazione: CodiceOperazione;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface OperazioneSpec {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AardOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codCommittente: string;
  note: string;
  aardProdotto1: AardProdotto1;
  aardProdotto2: AardProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AardProdotto1 {
  prodotto: AardProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AardProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AardProdotto2 {
  prodotto: AardProdotto2Prodotto;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  metodoPE: string;
  percIgp: number;
  percIgpSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AardProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AardDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codPartita: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  acetProdotto1: AcetProdotto1[];
  acetProdotto2: AcetProdotto2;
  acetProdotto3: AcetProdotto3;
  acetProdotto4: AcetProdotto4;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetProdotto1 {
  prodotto: AcetProdotto1Prodotto;
  codTenoreZucc: string;
  titoloAlcolEff: number;
  titoloAlcolPot: number;
  gradoAcidita: number;
  gradoAciditaSpecified: boolean;
  montegradi: number;
  montegradiSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetProdotto2 {
  prodotto: AcetProdotto2Prodotto;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetDesignProd2 {
  codCategoria: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetProdotto3 {
  prodotto: AcetProdotto3Prodotto;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetDesignProd3 {
  codCategoria: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetProdotto4 {
  prodotto: AcetProdotto4Prodotto;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  montegradi: number;
  montegradiSpecified: boolean;
  gradoAcidita: number;
  gradoAciditaSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetProdotto4Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcetDesignProd4 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  varieta: Cod_Valore[];
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  codPartita: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  acidProdotto1: AcidProdotto1;
  acidProdotto2: AcidProdotto2;
  acidProdotto3: AcidProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidProdotto1 {
  prodotto: AcidProdotto1Prodotto;
  codTenoreZucc: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  percIgp: number;
  percIgpSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidProdotto2 {
  prodotto: AcidProdotto2Prodotto;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidDesignProd2 {
  codCategoria: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidProdotto3 {
  prodotto: AcidProdotto3Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  metodoPE: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AcidDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  aprtProdotto1: AprtProdotto1;
  aprtProdotto2: AprtProdotto2;
  aprtProdotto3: AprtProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtProdotto1 {
  prodotto: AprtProdotto1Prodotto;
  codTenoreZucc: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  percIgp: number;
  percIgpSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtProdotto2 {
  prodotto: AprtProdotto2Prodotto;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtDesignProd2 {
  codCategoria: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtProdotto3 {
  prodotto: AprtProdotto3Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  trattamento: string;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AprtDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ARMCOperazione {
  numOperazione: number;
  dataOperazione: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  codCommittente: string;
  note: string;
  prodBase: ProdottoBase;
  prodAggiunto: ProdottoAggiunto;
  prodArricchito: ProdottoArricchito;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoBase {
  prodotto: ProdottoBaseProdotto;
  percIgp: number;
  percIgpSpecified: boolean;
  metodoPE: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoBaseProdotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoAggiunto {
  prodotto: ProdottoAggiuntoProdotto;
  titoloAlcolPot: number;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoAggiuntoProdotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoARMC2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  provenienza: string;
  origineUve: string;
  paesiProvenienza: PaesiProvenienza[];
  varieta: Cod_Valore[];
  codZonaViticola: string;
  codColore: string;
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoArricchito {
  prodotto: ProdottoArricchitoProdotto;
  codRecipiente: CodRecipiente[];
  percIgp: number;
  percIgpSpecified: boolean;
  metodoPE: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoArricchitoProdotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AucoOperazione {
  numOperazione: number;
  dataOperazione: string;
  note: string;
  aucoProdotto1: AucoProdotto1;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AucoProdotto1 {
  prodotto: AucoProdotto1Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AucoProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AucoDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  codPartita: string;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TipoPartita {
  volNominale: number;
  numConf: number;
  numSerie: string;
  numIniContr: string;
  numFinContr: string;
  lotto: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codCommittente: string;
  note: string;
  avltProdotto1: AvltProdotto1[];
  avltProdotto2: AvltProdotto2;
  avltProdotto3: AvltProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltProdotto1 {
  prodotto: AvltProdotto1Prodotto;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltProdotto2 {
  prodotto: AvltProdotto2Prodotto;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltDesignProd2 {
  codCategoria: string;
  codClassificazione: string;
  biologico: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltProdotto3 {
  prodotto: AvltProdotto3Prodotto;
  titoloAlcolTot: number;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AvltDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  annata: TipoAnnataCodice;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codCommittente: string;
  note: string;
  babsProdotto1: BabsProdotto1[];
  babsProdotto2: BabsProdotto2[];
  babsProdotto3: BabsProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsProdotto1 {
  prodotto: BabsProdotto1Prodotto;
  codTenoreZucc: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsProdotto2 {
  prodotto: BabsProdotto2Prodotto;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  menzioni: Menzioni[];
  biologico: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsProdotto3 {
  prodotto: BabsProdotto3Prodotto;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  percIgp: number;
  percIgpSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface BabsDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CasdOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codFornitore: string;
  codCommittente: string;
  note: string;
  prodCasd: ProdottoCasd[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoCasd {
  prodCasd1: ProdottoCasdProdCasd1;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  ggInvecchiamento: string;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoCasdProdCasd1 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoTipoA {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CertOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  certProdotto1: CertProdotto1;
  certProdotto2: CertProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CertProdotto1 {
  prodotto: CertProdotto1Prodotto;
  codTenoreZucc: string;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartitaNoContr[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CertProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CertDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TipoPartitaNoContr {
  volNominale: number;
  numConf: number;
  lotto: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CertProdotto2 {
  prodotto: CertProdotto2Prodotto;
  codTenoreZucc: string;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CertProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CertDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  codStatoFisico: string;
  dataCertDOP: string;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  dentProdotto1: DentProdotto1;
  dentProdotto2: DentProdotto2;
  dentProdotto3: DentProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentProdotto1 {
  prodotto: DentProdotto1Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codPartita: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentProdotto2 {
  prodotto: DentProdotto2Prodotto;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentDesignProd2 {
  codCategoria: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentProdotto3 {
  prodotto: DentProdotto3Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DentDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codPartita: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DeriOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codCommittente: string;
  note: string;
  deriProdotto1: DeriProdotto1;
  deriProdotto2: DeriProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DeriProdotto1 {
  prodotto: DeriProdotto1Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DeriProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DeriDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  codPartita: string;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DeriProdotto2 {
  prodotto: DeriProdotto2Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartitaNoContr[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DeriProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DeriDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codPartita: string;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  disaProdotto1: DisaProdotto1;
  disaProdotto2: DisaProdotto2;
  disaProdotto3: DisaProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaProdotto1 {
  prodotto: DisaProdotto1Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaProdotto2 {
  prodotto: DisaProdotto2Prodotto;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaDesignProd2 {
  codCategoria: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaProdotto3 {
  prodotto: DisaProdotto3Prodotto;
  codTenoreZucc: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  percIgp: number;
  percIgpSpecified: boolean;
  metodoPE: string;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DisaDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  distProdotto1: DistProdotto1[];
  distProdotto2: DistProdotto2;
  distProdotto3: DistProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistProdotto1 {
  prodotto: DistProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistProdotto2 {
  prodotto: DistProdotto2Prodotto;
  titoloAlcolEff: number;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  menzioni: Menzioni[];
  biologico: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistProdotto3 {
  prodotto: DistProdotto3Prodotto;
  titoloAlcolEff: number;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DistDesignProd3 {
  codCategoria: string;
  codClassificazione: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  dolcProdotto1: DolcProdotto1;
  dolcProdotto2: DolcProdotto2[];
  dolcProdotto3: DolcProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcProdotto1 {
  prodotto: DolcProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolTot: number;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcProdotto2 {
  prodotto: DolcProdotto2Prodotto;
  titoloAlcolPot: number;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcProdotto3 {
  prodotto: DolcProdotto3Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolTot: number;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DolcDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  codPartita: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ElmcOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codCommittente: string;
  note: string;
  elmcProdotto1: ElmcProdotto1;
  elmcProdotto2: ElmcProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ElmcProdotto1 {
  prodotto: ElmcProdotto1Prodotto;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ElmcProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ElmcDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ElmcProdotto2 {
  prodotto: ElmcProdotto2Prodotto;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ElmcProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ElmcDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EticOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  eticProdotto1: EticProdotto1;
  eticProdotto2: EticProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EticProdotto1 {
  prodotto: EticProdotto1Prodotto;
  codTenoreZucc: string;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EticProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EticDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EticProdotto2 {
  prodotto: EticProdotto2Prodotto;
  codTenoreZucc: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EticProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EticDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  evalProdotto1: EvalProdotto1;
  evalProdotto2: EvalProdotto2;
  evalProdotto3: EvalProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalProdotto1 {
  prodotto: EvalProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalProdotto2 {
  prodotto: EvalProdotto2Prodotto;
  titoloAlcolEff: number;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalDesignProd2 {
  codCategoria: string;
  biologico: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalProdotto3 {
  prodotto: EvalProdotto3Prodotto;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EvalDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codColore: string;
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  frabProdotto1: FrabProdotto1[];
  frabProdotto2: FrabProdotto2[];
  frabProdotto3: FrabProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabProdotto1 {
  prodotto: FrabProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabProdotto2 {
  prodotto: FrabProdotto2Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabProdotto3 {
  prodotto: FrabProdotto3Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrabDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrgsOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  frgsProdotto1: FrgsProdotto1[];
  frgsProdotto2: FrgsProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrgsProdotto1 {
  prodotto: FrgsProdotto1Prodotto;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrgsProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrgsDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  biologico: string;
  codColore: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrgsProdotto2 {
  prodotto: FrgsProdotto2Prodotto;
  codTenoreZucc: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrgsProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface FrgsDesignProd2 {
  codCategoria: string;
  codClassificazione: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codColore: string;
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GiinOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  prodGiin: ProdottoGiin;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoGiin {
  prodGiin1: ProdottoGiinProdGiin1;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  ggInvecchiamento: string;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoGiinProdGiin1 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoGiin1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ImboOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  prodImboS: ProdottoImboS;
  prodImboI: ProdottoImboI;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoImboS {
  prodImboS1: ProdottoImboSProdImboS1;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  quantitaPerdita: number;
  quantitaPerditaSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoImboSProdImboS1 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoImboA {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoImboI {
  prodImboI1: ProdottoImboIProdImboI1;
  codTenoreZucc: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  quantita: number;
  partita: TipoPartitaImbo[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoImboIProdImboI1 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoImboB {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TipoPartitaImbo {
  volNominale: number;
  numConf: number;
  numSerie: string;
  numIniContr: string;
  numFinContr: string;
  lotto: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codCommittente: string;
  note: string;
  lielProdotto1: LielProdotto1[];
  lielProdotto2: LielProdotto2[];
  lielProdotto3: LielProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielProdotto1 {
  prodotto: LielProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielProdotto2 {
  prodotto: LielProdotto2Prodotto;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielProdotto3 {
  prodotto: LielProdotto3Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface LielDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PerdOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  perdProdotto1: PerdProdotto1;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PerdProdotto1 {
  prodotto: PerdProdotto1Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PerdProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PerdDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  codPartita: string;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  pigiProdotto1: PigiProdotto1;
  pigiProdotto2: PigiProdotto2;
  pigiProdotto3: PigiProdotto3;
  pigiProdotto4: PigiProdotto4;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiProdotto1 {
  pigiDesignProd1: PigiProdotto1PigiDesignProd1;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  percIgp: number;
  percIgpSpecified: boolean;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiProdotto1PigiDesignProd1 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiProdotto2 {
  pigiDesignProd2: PigiProdotto2PigiDesignProd2;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiProdotto2PigiDesignProd2 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiDesignProd2 {
  codCategoria: string;
  codClassificazione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiProdotto3 {
  prodotto: PigiProdotto3Prodotto;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  biologico: string;
  annata: TipoAnnataCodice;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiProdotto4 {
  pigiDesignProd4: PigiProdotto4PigiDesignProd4;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiProdotto4PigiDesignProd4 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PigiDesignProd4 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codCommittente: string;
  note: string;
  scdsProdotto1: ScdsProdotto1;
  scdsProdotto2: ScdsProdotto2[];
  scdsProdotto3: ScdsProdotto3;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsProdotto1 {
  prodotto: ScdsProdotto1Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartitaNoContr[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsProdotto2 {
  prodotto: ScdsProdotto2Prodotto;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnataCodice;
  codStatoFisico: string;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsProdotto3 {
  prodotto: ScdsProdotto3Prodotto;
  codTenoreZucc: string;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartitaNoContr[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ScdsDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnataCodice;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SczcOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codCommittente: string;
  note: string;
  sczcProdotto1: SczcProdotto1[];
  sczcProdotto2: SczcProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SczcProdotto1 {
  prodotto: SczcProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SczcProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SczcDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  codStatoFisico: string;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SczcProdotto2 {
  prodotto: SczcProdotto2Prodotto;
  titoloAlcolTot: number;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SczcProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SczcDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  annata: TipoAnnataCodice;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  sfecProdotto1: SfecProdotto1;
  sfecProdotto2: SfecProdotto2;
  sfecProdotto3: SfecProdotto3[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecProdotto1 {
  prodotto: SfecProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  codPartita: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecProdotto2 {
  prodotto: SfecProdotto2Prodotto;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecDesignProd2 {
  codCategoria: string;
  codClassificazione: string;
  codDopIgp: string;
  provenienza: string;
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  biologico: string;
  codStatoFisico: string;
  attoCert: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecProdotto3 {
  prodotto: SfecProdotto3Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SfecDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codCommittente: string;
  note: string;
  spabProdotto1: SpabProdotto1[];
  spabProdotto2: SpabProdotto2;
  spabProdotto3: SpabProdotto3[];
  spabProdotto4: SpabProdotto4[];
  spabProdotto5: SpabProdotto5;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto1 {
  prodotto: SpabProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto2 {
  prodotto: SpabProdotto2Prodotto;
  titoloAlcolTot: number;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  annata: TipoAnnataCodice;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto3 {
  prodotto: SpabProdotto3Prodotto;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto3Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabDesignProd3 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  codColore: string;
  biologico: string;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  praticheEnologiche: PraticheEnologiche[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto4 {
  prodotto: SpabProdotto4Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto4Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabDesignProd4 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  annata: TipoAnnataCodice;
  codStatoFisico: string;
  codPartita: string;
  praticheEnologiche: PraticheEnologiche[];
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto5 {
  prodotto: SpabProdotto5Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpabProdotto5Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpgsOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  spgsProdotto1: SpgsProdotto1[];
  spgsProdotto2: SpgsProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpgsProdotto1 {
  prodotto: SpgsProdotto1Prodotto;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpgsProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpgsDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  codStatoFisico: string;
  codColore: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpgsProdotto2 {
  prodotto: SpgsProdotto2Prodotto;
  codTenoreZucc: string;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpgsProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SpgsDesignProd2 {
  codCategoria: string;
  codClassificazione: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codColore: string;
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SupeOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  supeProdotto1: SupeProdotto1[];
  supeProdotto2: SupeProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SupeProdotto1 {
  prodotto: SupeProdotto1Prodotto;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SupeProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SupeDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SupeProdotto2 {
  prodotto: SupeProdotto2Prodotto;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SupeProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SupeDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  codColore: string;
  praticheEnologiche: PraticheEnologiche[];
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  svinProdotto1: SvinProdotto1;
  svinProdotto2: SvinProdotto2;
  svinProdotto3: SvinProdotto3;
  svinProdotto4: SvinProdotto4;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinProdotto1 {
  svinDesignProd1: SvinProdotto1SvinDesignProd1;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinProdotto1SvinDesignProd1 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  praticheEnologiche: PraticheEnologiche[];
  biologico: string;
  annata: TipoAnnataCodice;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinProdotto2 {
  prodotto: SvinProdotto2Prodotto;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  provenienza: string;
  codZonaViticola: string;
  varieta: Cod_Valore[];
  altreVarieta: string;
  codSottozona: string;
  biologico: string;
  annata: TipoAnnataCodice;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinProdotto3 {
  svinDesignProd3: SvinProdotto3SvinDesignProd3;
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinProdotto3SvinDesignProd3 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinDesignProd3 {
  codCategoria: string;
  codClassificazione: string;
  biologico: string;
  codDopIgp: string;
  provenienza: string;
  codZonaViticola: string;
  varieta: Cod_Valore[];
  codSottozona: string;
  codStatoFisico: string;
  attoCert: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinProdotto4 {
  svinDesignProd4: SvinProdotto4SvinDesignProd4;
  percIgp: number;
  percIgpSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinProdotto4SvinDesignProd4 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SvinDesignProd4 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnataCodice;
  codStatoFisico: string;
  codPartita: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TaglOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  taglProdotto1: TaglProdotto1[];
  taglProdotto2: TaglProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TaglProdotto1 {
  prodotto: TaglProdotto1Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TaglProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TaglDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  codPartita: string;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TaglProdotto2 {
  prodotto: TaglProdotto2Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TaglProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TaglDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codPartita: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TrsoOperazione {
  numOperazione: number;
  dataOperazione: string;
  codCommittente: string;
  note: string;
  trsoProdotto1: TrsoProdotto1;
  trsoProdotto2: TrsoProdotto2;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TrsoProdotto1 {
  prodotto: TrsoProdotto1Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TrsoProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TrsoDesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TrsoProdotto2 {
  prodotto: TrsoProdotto2Prodotto;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TrsoProdotto2Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TrsoDesignProd2 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface UssdOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codDestinatario: string;
  codCommittente: string;
  note: string;
  prodUssd: ProdottoUssd[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoUssd {
  prodUssd1: ProdottoUssdProdUssd1;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codRecipiente: CodRecipiente[];
  quantita: number;
  partita: TipoPartita[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoUssdProdUssd1 {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoTipoB {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GENERICOProdotto1 {
  prodotto: GENERICOProdotto1Prodotto;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  codTenoreZucc: string;
  percIgp: number;
  percIgpSpecified: boolean;
  gradoAcidita: number;
  gradoAciditaSpecified: boolean;
  montegradi: number;
  montegradiSpecified: boolean;
  codRecipiente: CodRecipiente[];
  partita: TipoPartita[];
  quantita: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GENERICOProdotto1Prodotto {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GENERICODesignProd1 {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GENERICOOperazione {
  numOperazione: number;
  dataOperazione: string;
  esoneroDeroga: string;
  numGiustificativo: string;
  dataGiustificativo: string;
  dataGiustificativoSpecified: boolean;
  codFornitore: string;
  codCommittente: string;
  note: string;
  gENERICOProdotto1: GENERICOProdotto1;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CasdDaPreCasdOperazione {
  codiceAllegato: string;
  casd: CasdOperazione;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AziendaOutput {
  codiceAzienda: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Response {
  codice: string;
  messaggio: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaDopIgp {
  codDopIgp: string;
  descrizione: string;
  tipologiaVino: string;
  codPregresso: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DettOperStorOdc {
  dataInserimento: string;
  dataAnnullamento: string;
  statoOper: string;
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DettOperMoviOdc {
  dataMovimentazione: string;
  dataMovimentazioneSpecified: boolean;
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DettOperOdc {
  dataInserimento: string;
  dataInserimentoSpecified: boolean;
  statoOper: string;
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaStab {
  codOper: CUAA;
  codiceIcqrf: string;
  codiceProvincia: string;
  codiceVino: string;
  dataOperUltima: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaMVVODC {
  numMVV: string;
  dataVal: string;
  statoVal: ListaMVVODCStatoVal;
  cUAASped: CUAA;
  cUAADest: CUAA;
  codIcqrfSped: string;
  codIcqrfDest: string;
  prodottiMVV: ProdMVVRicevuto[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdMVVRicevuto {
  codTipoProdMVV: string;
  designazione: ProdottoCatalogo;
  codNC1: string;
  codNC2: string;
  codNC3: string;
  uniMis: string;
  quant: number;
  numColli: number;
  numColliSpecified: boolean;
  numImb: number;
  numImbSpecified: boolean;
  uniMisCapacitaImb: string;
  capacitaImb: number;
  capacitaImbSpecified: boolean;
  noteDes: string;
  codTenoreZucc: string;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  lotto: string;
  ggInvecchiamento: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoCatalogo {
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  paesiProvenienza: PaesiProvenienza[];
  codZonaViticola: string;
  varieta: Cod_ValorePerc[];
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  menzioni: Menzioni[];
  biologico: string;
  praticheEnologiche: PraticheEnologiche[];
  codPartita: string;
  annata: TipoAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DettaglioSoggettoMVVRicevuto_1 {
  cuaamvv: CUAA;
  tipoSoggMVV: DettaglioSoggettoMVVRicevuto_1TipoSoggMVV;
  nomeSoggMVV: string;
  cognomeSoggMVV: string;
  ragSocSoggMVV: string;
  indirizzoSoggMVV: Indirizzo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Indirizzo {
  cap: string;
  indirizzo1: string;
  provincia: string;
  comune: string;
  stato: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DettaglioSoggettoMVVRicevuto {
  cuaamvv: CUAA;
  nomeSoggMVV: string;
  cognomeSoggMVV: string;
  ragSocSoggMVV: string;
  indirizzoSoggMVV: Indirizzo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DettMVVDest {
  dettaglioSped: DettaglioSoggettoMVVRicevuto;
  lspedIndirizzo: Indirizzo;
  dettaglioDest: DettaglioSoggettoMVVRicevuto;
  dettaglioLDest: DettaglioSoggettoMVVRicevuto;
  ldestCodICRF: string;
  dettaglioVend: DettaglioSoggettoMVVRicevuto_1;
  dettaglioAcq: DettaglioSoggettoMVVRicevuto_1;
  dettaglioTras: DettaglioSoggettoMVVRicevuto_1;
  tipmeCod: string;
  mezzoTarga: string;
  rimorchioTarga: string;
  condNome: string;
  condCognome: string;
  titrCod: string;
  altreInfo: string;
  noteAltreAttes: string;
  dataTrasp: string;
  oraTrasp: DettMVVDestOraTrasp;
  minutiTrasp: DettMVVDestMinutiTrasp;
  prodottiMVV: ProdMVVRicevuto[];
  noteUlteriori: string;
  emailDestinatario: string;
  emailCondTras: string;
  cellCondTras: PrefCellCondTras;
  certificatoOrigine: Certificazione;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PrefCellCondTras {
  prefissoInt: string;
  cellulare: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Certificazione {
  certificato: Cert;
  statoOrigine?: Stato;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Cert {
  tipoCertOrigine: TipoCert;
  linguaCertificato?: CertLinguaCertificato;
  linguaCertificatoSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TipoCert {
  tipoA?: CertificatoTipoA;
  certTipoB?: TipoCertCertTipoB;
  certTipoBSpecified?: boolean;
  certTipoC?: TipoCertCertTipoC;
  certTipoCSpecified?: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CertificatoTipoA {
  certTipoA?: CertificatoTipoACertTipoA;
  listaEbacchus?: ListaEbacchusCert[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaEbacchusCert {
  codice: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Stato {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaMVVDest {
  numMVV: string;
  dataVal: string;
  statoVal: ListaMVVDestStatoVal;
  cUAASped: CUAA;
  codIcqrfSped: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Trasportatore {
  codiceTrasportatore: string;
  codiceCUAA: CUAA;
  tipoSoggetto: TrasportatoreTipoSoggetto;
  nome: string;
  cognome: string;
  ragioneSociale: string;
  indirizzoSede: Indirizzo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ListaMVV {
  numMVV: string;
  dataVal: string;
  dataValSpecified: boolean;
  dataAnn: string;
  dataAnnSpecified: boolean;
  statoVal: ListaMVVStatoVal;
  cUAADest: CUAA;
  codIcqrfDest: string;
  identLocaleMVV: string;
  marcaTemp: string;
  noteRicez: string;
  dataRicez: string;
  dataRicezSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface TrasportatoreOutput {
  codiceTrasportatore: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdMVV {
  codTipoProdMVV: string;
  prodotto: ProdMVVProdotto;
  codiciNomenclatura: CodiciNomenclatura;
  uniMis: string;
  quant: number;
  numColli: number;
  numColliSpecified: boolean;
  numImb: number;
  numImbSpecified: boolean;
  uniMisCapacitaImb: string;
  capacitaImb: number;
  capacitaImbSpecified: boolean;
  noteDes: string;
  codTenoreZucc: string;
  titoloAlcolPot: number;
  titoloAlcolPotSpecified: boolean;
  titoloAlcolEff: number;
  titoloAlcolEffSpecified: boolean;
  titoloAlcolTot: number;
  titoloAlcolTotSpecified: boolean;
  lotto: string;
  ggInvecchiamento: string;
}

export interface CodiciNomenclatura {
  codNC1: string;
  codNC2: string;
  codNC3: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdMVVProdotto {
  item: CodiceProdottoPrimario;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface DettaglioSoggettoMVV {
  codiceSoggettoMVV: string;
  cuaamvv: CUAA;
  tipoSoggMVV: DettaglioSoggettoMVVTipoSoggMVV;
  nomeSoggMVV: string;
  cognomeSoggMVV: string;
  ragSocSoggMVV: string;
  indirizzoSoggMVV: Indirizzo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface IndirizzoLsped {
  cap: string;
  indirizzo: string;
  provincia: string;
  comune: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EliminaProdCatalogoOutput {
  codiceProdotto: CodiceProdotto;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdottoEsito {
  codiceProdotto: CodiceProdotto;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface CodiceProdottoPrimario {
  codPrimario: string;
  codSecondario: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VasoVinario {
  codVaso: string;
  tipoVaso: string;
  descrizione: string;
  volume: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Vigna {
  codVigna: string;
  descrizione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface EliminaOperazioneOutput {
  dataOperazione: string;
  numOperazione: number;
  codOperazione: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VignaOutput {
  codVigna: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VasoVinarioOutput {
  codVaso: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ControlloEsito {
  dataOperazione: string;
  numOperazione: number;
  codOperazione: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface SoggettoOutput {
  codiceSoggetto: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface Soggetto {
  codiceSoggetto: string;
  codiceCUAA: CUAA;
  tipoSoggetto: SoggettoTipoSoggetto;
  nome: string;
  cognome: string;
  ragioneSociale: string;
  indirizzoSede: Indirizzo;
  flagDest: number;
  flagDestSpecified: boolean;
  codiceIcqrfSogg: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisSoggSiRPVInput {
  codOper: CUAA;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisSoggSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  dettaglioSoggetto: Soggetto[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisSoggSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visSoggSiRPVInput: VisSoggSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisSoggSiRPVResponse {
  visSoggSiRPVOutput: VisSoggSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetSoggSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetSoggSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  soggetto: SoggettoOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetSoggSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getSoggSiRPVInput: GetSoggSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetSoggSiRPVResponse {
  getSoggSiRPVOutput: GetSoggSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetOperSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetOperSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  controlloEsito: ControlloEsito[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetOperSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getOperSiRPVInput: GetOperSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetOperSiRPVResponse {
  getOperSiRPVOutput: GetOperSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetVasiSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetVasiSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  vasi: VasoVinarioOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetVasiSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getVasiSiRPVInput: GetVasiSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetVasiSiRPVResponse {
  getVasiSiRPVOutput: GetVasiSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetVigneSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetVigneSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  vigne: VignaOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetVigneSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getVigneSiRPVInput: GetVigneSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetVigneSiRPVResponse {
  getVigneSiRPVOutput: GetVigneSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancSoggSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancSoggSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  soggettoOutput: SoggettoOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancSoggSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getCancSoggSiRPVInput: GetCancSoggSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancSoggSiRPVResponse {
  getCancSoggSiRPVOutput: GetCancSoggSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancOperSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancOperSiRPVOutput {
  codOper: string;
  codiceIcqrf: string;
  esito: Response;
  eliminaOperazioneOutput: EliminaOperazioneOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancOperSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getCancOperSiRPVInput: GetCancOperSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancOperSiRPVResponse {
  getCancOperSiRPVOutput: GetCancOperSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancVasiSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancVasiSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  vasiOutput: VasoVinarioOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancVasiSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getCancVasiSiRPVInput: GetCancVasiSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancVasiSiRPVResponse {
  getCancVasiSiRPVOutput: GetCancVasiSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancVigneSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancVigneSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  vigneOutput: VignaOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancVigneSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getCancVigneSiRPVInput: GetCancVigneSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancVigneSiRPVResponse {
  getCancVigneSiRPVOutput: GetCancVigneSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  dataOperIni: string;
  dataOperFine: string;
  dataOperFineSpecified: boolean;
  numOperazione: number;
  numOperazioneSpecified: boolean;
  codiceFornDest: string;
  codOperazione: CodiceOperazione;
  codOperazioneSpecified: boolean;
  categoria: string;
  codCommittente: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperSiRPVOutput {
  esito: Response;
  dettaglioOperazione: VisOperSiRPVOutputDettaglioOperazione[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperSiRPVOutputDettaglioOperazione {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visOperSiRPVInput: VisOperSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperSiRPVResponse {
  visOperSiRPVOutput: VisOperSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVigneSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  codVigna: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVigneSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  dettaglioVigne: Vigna[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVigneSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visVigneSiRPVInput: VisVigneSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVigneSiRPVResponse {
  visVigneSiRPVOutput: VisVigneSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVasiSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  codVaso: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVasiSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  dettaglioVasi: VasoVinario[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVasiSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visVasiSiRPVInput: VisVasiSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVasiSiRPVResponse {
  visVasiSiRPVOutput: VisVasiSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancAzieSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancAzieSiRPVOutput {
  codOper: CUAA;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancAzieSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getCancAzieSiRPVInput: GetCancAzieSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancAzieSiRPVResponse {
  getCancAzieSiRPVOutput: GetCancAzieSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisProdSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  codiceProdotto: CodiceProdottoPrimario;
  codCategoria: string;
  codClassificazione: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisProdSiRPVOutput {
  esito: Response;
  prodCatalogo: VisProdSiRPVOutputProdCatalogo[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisProdSiRPVOutputProdCatalogo {
  codiceProdotto: CodiceProdotto;
  designazione: ProdottoCatalogo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisProdSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visProdSiRPVInput: VisProdSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisProdSiRPVResponse {
  visProdSiRPVOutput: VisProdSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  dataGiacenza: string;
  codiceProdotto: CodiceProdottoPrimario;
  codCommittente: string;
  codCategoria: string;
  attoCert: string;
  codClassificazione: string;
  codDopIgp: string;
  codEbacchus: string;
  origineUve: string;
  provenienza: string;
  selPaesiProvenienza: VisGiacSiRPVInputSelPaesiProvenienza;
  codZonaViticola: string;
  selVarieta: VisGiacSiRPVInputSelVarieta;
  altreVarieta: string;
  codSottozona: string;
  codVigna: string;
  codColore: string;
  selMenzioni: VisGiacSiRPVInputSelMenzioni;
  biologico: string;
  selPratiche: VisGiacSiRPVInputSelPratiche;
  codPartita: string;
  annata: VisGiacSiRPVInputAnnata;
  massaVolumica: number;
  massaVolumicaSpecified: boolean;
  codStatoFisico: string;
  dataCertDOP: string;
  dataCertDOPSpecified: boolean;
  numCertDOP: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVInputSelPaesiProvenienza {
  tipoFiltro: TipoFiltro;
  paesiProvenienza: PaesiProvenienza[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVInputSelVarieta {
  tipoFiltro: TipoFiltro;
  varieta: Cod_ValorePerc[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVInputSelMenzioni {
  tipoFiltro: TipoFiltro;
  menzioni: Menzioni[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVInputSelPratiche {
  tipoFiltro: TipoFiltro;
  praticheEnologiche: PraticheEnologiche[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVInputAnnata {
  anno: number;
  percentuale: number;
  percentualeSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVOutput {
  esito: Response;
  prodGiacenza: VisGiacSiRPVOutputProdGiacenza[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVOutputProdGiacenza {
  codiceProdotto: CodiceProdotto;
  designazione: ProdottoCatalogo;
  codCommittente: string;
  unMis: string;
  qtaGiacenza: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visGiacSiRPVInput: VisGiacSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacSiRPVResponse {
  visGiacSiRPVOutput: VisGiacSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetProdSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetProdSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  prodottoEsito: ProdottoEsito[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetProdSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getProdSiRPVInput: GetProdSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetProdSiRPVResponse {
  getProdSiRPVOutput: GetProdSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancProdSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancProdSiRPVOutput {
  codOper: string;
  codiceIcqrf: string;
  esito: Response;
  eliminaProdOutput: EliminaProdCatalogoOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancProdSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getCancProdSiRPVInput: GetCancProdSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancProdSiRPVResponse {
  getCancProdSiRPVOutput: GetCancProdSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MVVSiRPVInput {
  codOper: CUAA;
  codIcqrfSped: string;
  lspedIndirizzo: IndirizzoLsped;
  flagArt33: MVVSiRPVInputFlagArt33;
  flagArt33Specified: boolean;
  flagArt29: MVVSiRPVInputFlagArt29;
  flagArt29Specified: boolean;
  destinatario: MVVSiRPVInputDestinatario;
  luogoDestinatario: MVVSiRPVInputLuogoDestinatario;
  ldestCodICRF: string;
  venditore: MVVSiRPVInputVenditore;
  acquirente: MVVSiRPVInputAcquirente;
  trasportatore: MVVSiRPVInputTrasportatore;
  tipmeCod: string;
  mezzoTarga: string;
  rimorchioTarga: string;
  condNome: string;
  condCognome: string;
  titrCod: string;
  altreInfo: string;
  noteAltreAttes: string;
  dataTrasp: string;
  oraTrasp: MVVSiRPVInputOraTrasp;
  minutiTrasp: MVVSiRPVInputMinutiTrasp;
  prodottiMVV: ProdMVV[];
  noteUlteriori: string;
  emailDestinatario: string;
  emailCondTras: string;
  cellCondTras: PrefCellCondTras;
  certificatoOrigine: Certificazione;
  identLocaleMVV: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MVVSiRPVInputDestinatario {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MVVSiRPVInputLuogoDestinatario {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MVVSiRPVInputVenditore {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MVVSiRPVInputAcquirente {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MVVSiRPVInputTrasportatore {
  item: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MVVSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  numMVV: string;
  marcaTemp: string;
  filePdf: number[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MVVSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  mVVSiRPVInput: MVVSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface MVVSiRPVResponse {
  mVVSiRPVOutput: MVVSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetTrasSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetTrasSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  trasportatore: TrasportatoreOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetTrasSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getTrasSiRPVInput: GetTrasSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetTrasSiRPVResponse {
  getTrasSiRPVOutput: GetTrasSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancTrasSiRPVInput {
  idTrasmissione: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancTrasSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  trasportatoreOutput: TrasportatoreOutput[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancTrasSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getCancTrasSiRPVInput: GetCancTrasSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetCancTrasSiRPVResponse {
  getCancTrasSiRPVOutput: GetCancTrasSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVSiRPVInput {
  codOper: CUAA;
  codIcqrfSped: string;
  numMVV: string;
  selDataMVV: VisMVVSiRPVInputSelDataMVV;
  statoVal: VisMVVSiRPVInputStatoVal;
  statoValSpecified: boolean;
  titrCod: string;
  cUAADest: CUAA;
  codIcqrfDest: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVSiRPVInputSelDataMVV {
  dataValIni: string;
  dataValFine: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  listaMVV: ListaMVV[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visMVVSiRPVInput: VisMVVSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVSiRPVResponse {
  visMVVSiRPVOutput: VisMVVSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnMVVSiRPVInput {
  codOper: CUAA;
  codIcqrf: string;
  numMVV: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnMVVSiRPVOutput {
  codOper: CUAA;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnMVVSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  annMVVSiRPVInput: AnnMVVSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnMVVSiRPVResponse {
  annMVVSiRPVOutput: AnnMVVSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PrnMVVSiRPVInput {
  codOper: CUAA;
  codIcqrf: string;
  numMVV: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PrnMVVSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  filePdf: number[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PrnMVVSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  prnMVVSiRPVInput: PrnMVVSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PrnMVVSiRPVResponse {
  prnMVVSiRPVOutput: PrnMVVSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisTrasSiRPVInput {
  codOper: CUAA;
  codiceTrasportatore: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisTrasSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  dettaglioTrasportatore: Trasportatore[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisTrasSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visTrasSiRPVInput: VisTrasSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisTrasSiRPVResponse {
  visTrasSiRPVOutput: VisTrasSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AggNoteMVVSiRPVInput {
  codOper: CUAA;
  codIcqrf: string;
  numMVV: string;
  ulterioriNote: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AggNoteMVVSiRPVOutput {
  codOper: CUAA;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AggNoteMVVSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  aggNoteMVVSiRPVInput: AggNoteMVVSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AggNoteMVVSiRPVResponse {
  aggNoteMVVSiRPVOutput: AggNoteMVVSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsRicMvvSiRPVInput {
  codOper: CUAA;
  codIcqrfDest: string;
  numMVV: string;
  statoVal: InsRicMvvSiRPVInputStatoVal;
  noteRicez: string;
  dataRicez: string;
  dataRicezSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsRicMvvSiRPVOutput {
  esito: Response;
  numMVV: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsRicMvvSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  insRicMvvSiRPVInput: InsRicMvvSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsRicMvvSiRPVResponse {
  insRicMvvSiRPVOutput: InsRicMvvSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVDestSiRPVInput {
  codOper: CUAA;
  codIcqrfDest: string;
  numMVV: string;
  selDataMVV: VisMVVDestSiRPVInputSelDataMVV;
  statoVal: VisMVVDestSiRPVInputStatoVal;
  statoValSpecified: boolean;
  titrCod: string;
  cUAASped: CUAA;
  codIcqrfSped: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVDestSiRPVInputSelDataMVV {
  dataValIni: string;
  dataValFine: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVDestSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  listaMVVDest: ListaMVVDest[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVDestSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visMVVDestSiRPVInput: VisMVVDestSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVDestSiRPVResponse {
  visMVVDestSiRPVOutput: VisMVVDestSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisDettMVVDestSiRPVInput {
  codOper: CUAA;
  codIcqrfDest: string;
  numMVV: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisDettMVVDestSiRPVOutput {
  codOper: CUAA;
  codIcqrfDest: string;
  esito: Response;
  dettMVVDest: DettMVVDest;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisDettMVVDestSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visDettMVVDestSiRPVInput: VisDettMVVDestSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisDettMVVDestSiRPVResponse {
  visDettMVVDestSiRPVOutput: VisDettMVVDestSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdSincrSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  tipoRichiesta: TipoRichiesta;
  prodCatalogo: ProdSincrSiRPVInputProdCatalogo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdSincrSiRPVInputProdCatalogo {
  codiceProdotto: CodiceProdotto;
  designazione: ProdottoCatalogo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ProdSincrSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  prodottoEsito: ProdottoEsito;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsAggProdSincrSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  prodSincrSiRPVInput: ProdSincrSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsAggProdSincrSiRPVResponse {
  prodSincrSiRPVOutput: ProdSincrSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PrnMVVODCSiRPVInput {
  codOper: CUAA;
  codIcqrf: string;
  numMVV: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PrnMVVODCSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  filePdf: number[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PrnMVVODCSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  prnMVVODCSiRPVInput: PrnMVVODCSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface PrnMVVODCSiRPVResponse {
  prnMVVODCSiRPVOutput: PrnMVVODCSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVODCSiRPVInput {
  codOper: CUAA;
  numMVV: string;
  selDataMVV: VisMVVODCSiRPVInputSelDataMVV;
  statoVal: VisMVVODCSiRPVInputStatoVal;
  statoValSpecified: boolean;
  cUAASped: CUAA;
  cUAADest: CUAA;
  flagEstero: VisMVVODCSiRPVInputFlagEstero;
  flagEsteroSpecified: boolean;
  descVino: string;
  codCategoria: string;
  codClassificazione: string;
  codStatoFisico: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVODCSiRPVInputSelDataMVV {
  dataValIni: string;
  dataValFine: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVODCSiRPVOutput {
  codOper: CUAA;
  esito: Response;
  listaMVVODC: ListaMVVODC[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVODCSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visMVVODCSiRPVInput: VisMVVODCSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMVVODCSiRPVResponse {
  visMVVODCSiRPVOutput: VisMVVODCSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisStabSiRPVInput {
  codOdc: string;
  codOper: CUAA;
  codiceProvincia: string;
  codiceVino: string;
  dataOperIni: string;
  dataOperIniSpecified: boolean;
  dataOperFine: string;
  dataOperFineSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisStabSiRPVOutput {
  esito: Response;
  listaStab: ListaStab[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisStabSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visStabSiRPVInput: VisStabSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisStabSiRPVResponse {
  visStabSiRPVOutput: VisStabSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperOdcSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  dataOperIni: string;
  dataOperFine: string;
  dataOperFineSpecified: boolean;
  numOperazione: number;
  numOperazioneSpecified: boolean;
  codiceFornDest: string;
  codOperazione: CodiceOperazione;
  codOperazioneSpecified: boolean;
  categoria: string;
  codCommittente: string;
  dataInserIni: string;
  dataInserFine: string;
  dataInserFineSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperOdcSiRPVOutput {
  esito: Response;
  dataEstrazione: string;
  dettaglioOperazione: DettOperOdc[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperOdcSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visOperOdcSiRPVInput: VisOperOdcSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperOdcSiRPVResponse {
  visOperOdcSiRPVOutput: VisOperOdcSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperAttiveOdcSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  dataOperIni: string;
  dataOperFine: string;
  dataOperFineSpecified: boolean;
  numOperazione: number;
  numOperazioneSpecified: boolean;
  codiceFornDest: string;
  codOperazione: CodiceOperazione;
  codOperazioneSpecified: boolean;
  categoria: string;
  codCommittente: string;
  dataIni: string;
  statoOper: VisOperAttiveOdcSiRPVInputStatoOper;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperAttiveOdcSiRPVOutput {
  esito: Response;
  dataEstrazione: string;
  dettaglioOperazione: DettOperMoviOdc[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperAttiveOdcSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visOperAttiveOdcSiRPVInput: VisOperAttiveOdcSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperAttiveOdcSiRPVResponse {
  visOperAttiveOdcSiRPVOutput: VisOperAttiveOdcSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperStoricizzateOdcSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  dataOperIni: string;
  dataOperFine: string;
  dataOperFineSpecified: boolean;
  numOperazione: number;
  numOperazioneSpecified: boolean;
  codiceFornDest: string;
  codOperazione: CodiceOperazione;
  codOperazioneSpecified: boolean;
  categoria: string;
  codCommittente: string;
  dataStoricizzazioneIni: string;
  dataStoricizzazioneFine: string;
  dataStoricizzazioneFineSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperStoricizzateOdcSiRPVOutput {
  esito: Response;
  dataEstrazione: string;
  dettaglioOperazione: DettOperStorOdc[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperStoricizzateOdcSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visOperStoricizzateOdcSiRPVInput: VisOperStoricizzateOdcSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisOperStoricizzateOdcSiRPVResponse {
  visOperStoricizzateOdcSiRPVOutput: VisOperStoricizzateOdcSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ChiudiCampagnaPrecSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ChiudiCampagnaPrecSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ChiudiCampagnaPrecSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  chiudiCampagnaPrecSiRPVInput: ChiudiCampagnaPrecSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface ChiudiCampagnaPrecSiRPVResponse {
  chiudiCampagnaPrecSiRPVOutput: ChiudiCampagnaPrecSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetStatoChiusuraCampagnaPrecSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetStatoChiusuraCampagnaPrecSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetStatoChiusuraCampagnaPrecSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  getStatoChiusuraCampagnaPrecSiRPVInput: GetStatoChiusuraCampagnaPrecSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetStatoChiusuraCampagnaPrecSiRPVResponse {
  getStatoChiusuraCampagnaPrecSiRPVOutput: GetStatoChiusuraCampagnaPrecSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisDopIgpSiRPVInput {
  codDopIgp: string;
  descrDopIgp: string;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisDopIgpSiRPVOutput {
  esito: Response;
  listaDopIgp: ListaDopIgp[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisDopIgpSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visDopIgpSiRPVInput: VisDopIgpSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisDopIgpSiRPVResponse {
  visDopIgpSiRPVOutput: VisDopIgpSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisZonaViticolaSiRPVOutput {
  esito: Response;
  listaZonaViticola: ListaZonaViticola[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisZonaViticolaSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visSiRPVInput: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisZonaViticolaSiRPVResponse {
  visZonaViticolaSiRPVOutput: VisZonaViticolaSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMenzioneSiRPVOutput {
  esito: Response;
  listaMenzione: ListaMenzione[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMenzioneSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visSiRPVInput: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisMenzioneSiRPVResponse {
  visMenzioneSiRPVOutput: VisMenzioneSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisSottozonaSiRPVOutput {
  esito: Response;
  listaSottozona: ListaSottozona[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisSottozonaSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visSiRPVInput: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisSottozonaSiRPVResponse {
  visSottozonaSiRPVOutput: VisSottozonaSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVarietaSiRPVOutput {
  esito: Response;
  listaVarieta: ListaVarieta[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVarietaSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visSiRPVInput: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisVarietaSiRPVResponse {
  visVarietaSiRPVOutput: VisVarietaSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisEbacchusSiRPVOutput {
  esito: Response;
  listaEbacchus: ListaEbacchus[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisEbacchusSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visSiRPVInput: any;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisEbacchusSiRPVResponse {
  visEbacchusSiRPVOutput: VisEbacchusSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacDichiarazioniSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  annoInizioCampagna: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisGiacDichiarazioniSiRPVOutput {
  esito: Response;
  vinoTavola: QtaDichGiacenza;
  vinoVarietale: QtaDichGiacenza;
  vinoIgp: QtaDichGiacenza;
  vinoDop: QtaDichGiacenza;
  mostoConcentrato: QtaDichGiacenzaSoloTotale;
  mostoConcentratoRettificato: QtaDichGiacenzaSoloTotale;
  altriMosti: QtaDichGiacenza;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetGiacenza31luglioRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visGiacDichiarazioniSiRPVInput: VisGiacDichiarazioniSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface GetGiacenza31luglioResponse {
  visGiacDichiarazioniSiRPVOutput: VisGiacDichiarazioniSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevSpumantizzazioneSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  dataOperazione: string;
  metodo: Metodo;
  prodCatalogoCuvee: SpabProdottoSpum1[];
  prodCatalogoAttoDiventare: SpabProdottoSpum5;
  arricchimento: Arricchimento;
  presaSpuma: PresaSpuma;
  scorporo: Scorporo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevSpumantizzazioneSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  protocollo: Protocollo;
  numeroDichiarazione: number;
  numeroDichiarazioneSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevSpumantizzazioneSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  insPrevSpumantizzazioneSiRPVInput: InsPrevSpumantizzazioneSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevSpumantizzazioneSiRPVResponse {
  insPrevSpumantizzazioneSiRPVOutput: InsPrevSpumantizzazioneSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevSpumantizzazioneSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  annoCampagna: number;
  numeroDichiarazione: number;
  numeroDichiarazioneSpecified: boolean;
  protocolloRicerca: ProtocolloRicerca;
  stato: StatoDichiarazione;
  statoSpecified: boolean;
  denomProdOttenuto: string;
  dataAggiornamento: Periodo;
  dataOperazione: Periodo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevSpumantizzazioneSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  dettaglioSpumantizzazione: VisPrevSpumantizzazioneSiRPVOutputDettaglioSpumantizzazione[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevSpumantizzazioneSiRPVOutputDettaglioSpumantizzazione {
  dataAggiornamento: string;
  dataOperazione: string;
  stato: StatoDichiarazione;
  numeroDichiarazione: number;
  protocollo: Protocollo;
  annoCampagna: number;
  cfOperatore: string;
  metodo: Metodo;
  prodCatalogoCuvee: SpabProdottoSpum1[];
  prodCatalogoAttoDiventare: SpabProdottoSpum5;
  arricchimento: Arricchimento;
  presaSpuma: PresaSpuma;
  ottenutoTotHL: number;
  ottenutoTotHLSpecified: boolean;
  scorporo: Scorporo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevSpumantizzazioneSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visPrevSpumantizzazioneSiRPVInput: VisPrevSpumantizzazioneSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevSpumantizzazioneSiRPVResponse {
  visPrevSpumantizzazioneSiRPVOutput: VisPrevSpumantizzazioneSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevArricchimentoSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  dataOperazione: string;
  metodoImpiegato: MetodoArricchimento;
  prodAmonte: ProdottoMonteArricchimento;
  prodAttoDiventare: ProdottoAttoDiventareArricchimento;
  dichiarazione: DichiarazioneArricchimento;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevArricchimentoSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  protocollo: Protocollo;
  numeroDichiarazione: number;
  numeroDichiarazioneSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevArricchimentoSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  insPrevArricchimentoSiRPVInput: InsPrevArricchimentoSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevArricchimentoSiRPVResponse {
  insPrevArricchimentoSiRPVOutput: InsPrevArricchimentoSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevArricchimentoSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  annoCampagna: number;
  numeroDichiarazione: number;
  numeroDichiarazioneSpecified: boolean;
  protocolloRicerca: ProtocolloRicerca;
  stato: StatoDichiarazione;
  statoSpecified: boolean;
  dataAggiornamento: Periodo;
  dataOperazione: Periodo;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevArricchimentoSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  dettaglioArricchimento: VisPrevArricchimentoSiRPVOutputDettaglioArricchimento[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevArricchimentoSiRPVOutputDettaglioArricchimento {
  icqrfTerritoriale: string;
  cuaODC: string;
  stato: StatoDichiarazione;
  dataAggiornamento: string;
  numeroDichiarazione: number;
  numeroDichiarazioneSpecified: boolean;
  protocollo: Protocollo;
  annoCampagna: number;
  dataOperazione: string;
  metodoImpiegato: MetodoArricchimento;
  prodAmonte: ArricchimentoDesignProdMonte;
  prodAttoDiventare: ProdottoAttoDiventareArricchimento;
  dichiarazione: DichiarazioneArricchimento;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevArricchimentoSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visPrevArricchimentoSiRPVInput: VisPrevArricchimentoSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevArricchimentoSiRPVResponse {
  visPrevArricchimentoSiRPVOutput: VisPrevArricchimentoSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevArricchimentoSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  annoCampagna: number;
  numeroDichiarazione: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevArricchimentoSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  dichiarazioneAnnullaOutput: DichAnnullaOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevArricchimentoSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  annullaPrevArricchimentoSiRPVInput: AnnullaPrevArricchimentoSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevArricchimentoSiRPVResponse {
  annullaPrevArricchimentoSiRPVOutput: AnnullaPrevArricchimentoSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevSpumantizzazioneSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  annoCampagna: number;
  numeroDichiarazione: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevSpumantizzazioneSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  dichiarazioneAnnullaOutput: DichAnnullaOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevSpumantizzazioneSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  annullaPrevSpumantizzazioneSiRPVInput: AnnullaPrevSpumantizzazioneSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevSpumantizzazioneSiRPVResponse {
  annullaPrevSpumantizzazioneSiRPVOutput: AnnullaPrevSpumantizzazioneSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevcumArricchimentoSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  aggiuntaMc: string;
  aggiuntaMcr: string;
  concentrParziale: string;
  prodAttoDiventare: DettaglioProdAttoDiventare[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevcumArricchimentoSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  protocollo: Protocollo;
  annoCampagna: number;
  annoCampagnaSpecified: boolean;
  progrCumulativaArricchimento: number;
  progrCumulativaArricchimentoSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevcumArricchimentoSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  insPrevcumArricchimentoSiRPVInput: InsPrevcumArricchimentoSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface InsPrevcumArricchimentoSiRPVResponse {
  insPrevcumArricchimentoSiRPVOutput: InsPrevcumArricchimentoSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevCumArricchimentoSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  annoCampagna: number;
  progrCumulativaArricchimento: number;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevCumArricchimentoSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  dichiarazioneAnnullaOutput: DichAnnullaOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevcumArricchimentoSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  annullaPrevCumArricchimentoSiRPVInput: AnnullaPrevCumArricchimentoSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface AnnullaPrevcumArricchimentoSiRPVResponse {
  annullaPrevCumArricchimentoSiRPVOutput: AnnullaPrevCumArricchimentoSiRPVOutput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevcumArricchimentoSiRPVInput {
  codOper: CUAA;
  codiceIcqrf: string;
  annoCampagna: number;
  progrCumulativaArricchimento: number;
  progrCumulativaArricchimentoSpecified: boolean;
  stato: StatoDichiarazione;
  statoSpecified: boolean;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevcumArricchimentoSiRPVOutput {
  codOper: CUAA;
  codiceIcqrf: string;
  esito: Response;
  dettaglioCumArricchimento: VisPrevcumArricchimentoSiRPVOutputDettaglioCumArricchimento[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevcumArricchimentoSiRPVOutputDettaglioCumArricchimento {
  icqrfTerritoriale: string;
  stato: StatoDichiarazione;
  annoCampagna: number;
  dataInizioValidita: string;
  dataInizioValiditaSpecified: boolean;
  dataFineValidita: string;
  protocollo: Protocollo;
  progrCumulativaArricchimento: number;
  progrCumulativaArricchimentoSpecified: boolean;
  aggiuntaMc: string;
  aggiuntaMcr: string;
  concentrParziale: string;
  prodAttoDiventare: DettaglioProdAttoDiventare[];
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevcumArricchimentoSiRPVRequest {
  sOAPAutenticazione: SOAPAutenticazione;
  visPrevcumArricchimentoSiRPVInput: VisPrevcumArricchimentoSiRPVInput;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface VisPrevcumArricchimentoSiRPVResponse {
  visPrevcumArricchimentoSiRPVOutput: VisPrevcumArricchimentoSiRPVOutput;
}
/*
// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface wsRegVinoInterServiceChannel extends wsRegVinoInterService, System.ServiceModel.IClientChannel {
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export interface wsRegVinoInterServiceClient extends System.ServiceModel.ClientBase<wsRegVinoInterService>, wsRegVinoInterService {
}*/

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum ItemChoiceType3 {
  AggiuntaSaccarosio = 0,
  Metodo = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum ItemChoiceType2 {
  AggiuntaMcrlSpuma = 0,
  AggiuntaMcrsKgSpuma = 1,
  AggiuntaSaccarosioKgSpuma = 2,
  GradoFinaleMcrlSpuma = 3,
  GradoFinaleMcrsGradoSpuma = 4,
  GradoFinaleSaccarosioGradoSpuma = 5,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum ItemChoiceType1 {
  AggiuntaMcrlArric = 0,
  AggiuntaMcrsKgArric = 1,
  AggiuntaSaccarosioKgArric = 2,
  GradoFinaleMcrlArric = 3,
  GradoFinaleMcrsGradoArric = 4,
  GradoFinaleSaccarosioGradoArric = 5,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum ItemChoiceType {
  PersonaFisica = 0,
  PersonaGiuridica = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum ListaPreCasdCodiceColore {
  B = 0,
  N = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum CodiceOperazione {
  GIIN = 0,
  CASD = 1,
  USSD = 2,
  IMBO = 3,
  ACID = 4,
  PIGI = 5,
  ARMC = 6,
  DOLC = 7,
  PERD = 8,
  SVIN = 9,
  TAGL = 10,
  CERT = 11,
  AUCO = 12,
  DIST = 13,
  DENT = 14,
  SFEC = 15,
  AVLT = 16,
  SPGS = 17,
  SPAB = 18,
  SCDS = 19,
  ELMC = 20,
  FRGS = 21,
  SCZC = 22,
  LIEL = 23,
  EVAL = 24,
  FRAB = 25,
  AARD = 26,
  DISA = 27,
  BABS = 28,
  APRT = 29,
  ETIC = 30,
  SUPE = 31,
  DERI = 32,
  ACET = 33,
  TRSO = 34,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum ListaMVVODCStatoVal {
  Item02 = 0,
  Item03 = 1,
  Item04 = 2,
  Item05 = 3,
  Item06 = 4,
  Item07 = 5,
  Item08 = 6,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum DettaglioSoggettoMVVRicevuto_1TipoSoggMVV {
  IT = 0,
  UE = 1,
  EX = 2,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum DettMVVDestOraTrasp {
  Item00 = 0,
  Item01 = 1,
  Item02 = 2,
  Item03 = 3,
  Item04 = 4,
  Item05 = 5,
  Item06 = 6,
  Item07 = 7,
  Item08 = 8,
  Item09 = 9,
  Item10 = 10,
  Item11 = 11,
  Item12 = 12,
  Item13 = 13,
  Item14 = 14,
  Item15 = 15,
  Item16 = 16,
  Item17 = 17,
  Item18 = 18,
  Item19 = 19,
  Item20 = 20,
  Item21 = 21,
  Item22 = 22,
  Item23 = 23,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum DettMVVDestMinutiTrasp {
  Item00 = 0,
  Item01 = 1,
  Item02 = 2,
  Item03 = 3,
  Item04 = 4,
  Item05 = 5,
  Item06 = 6,
  Item07 = 7,
  Item08 = 8,
  Item09 = 9,
  Item10 = 10,
  Item11 = 11,
  Item12 = 12,
  Item13 = 13,
  Item14 = 14,
  Item15 = 15,
  Item16 = 16,
  Item17 = 17,
  Item18 = 18,
  Item19 = 19,
  Item20 = 20,
  Item21 = 21,
  Item22 = 22,
  Item23 = 23,
  Item24 = 24,
  Item25 = 25,
  Item26 = 26,
  Item27 = 27,
  Item28 = 28,
  Item29 = 29,
  Item30 = 30,
  Item31 = 31,
  Item32 = 32,
  Item33 = 33,
  Item34 = 34,
  Item35 = 35,
  Item36 = 36,
  Item37 = 37,
  Item38 = 38,
  Item39 = 39,
  Item40 = 40,
  Item41 = 41,
  Item42 = 42,
  Item43 = 43,
  Item44 = 44,
  Item45 = 45,
  Item46 = 46,
  Item47 = 47,
  Item48 = 48,
  Item49 = 49,
  Item50 = 50,
  Item51 = 51,
  Item52 = 52,
  Item53 = 53,
  Item54 = 54,
  Item55 = 55,
  Item56 = 56,
  Item57 = 57,
  Item58 = 58,
  Item59 = 59,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum CertificatoTipoACertTipoA {
  A = 0,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum TipoCertCertTipoB {
  B = 0,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum TipoCertCertTipoC {
  C = 0,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum CertLinguaCertificato {
  ENG = 0,
  SPA = 1,
  FRA = 2,
  DEU = 3,
  CIN = 4,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum StatoFlagUe {
  U = 0,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum ListaMVVDestStatoVal {
  Item02 = 0,
  Item03 = 1,
  Item04 = 2,
  Item05 = 3,
  Item06 = 4,
  Item07 = 5,
  Item08 = 6,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum TrasportatoreTipoSoggetto {
  IT = 0,
  UE = 1,
  EX = 2,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum ListaMVVStatoVal {
  Item02 = 0,
  Item03 = 1,
  Item04 = 2,
  Item05 = 3,
  Item06 = 4,
  Item07 = 5,
  Item08 = 6,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum DettaglioSoggettoMVVTipoSoggMVV {
  IT = 0,
  UE = 1,
  EX = 2,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum SoggettoTipoSoggetto {
  IT = 0,
  UE = 1,
  EX = 2,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum TipoFiltro {
  S = 0,
  N = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum MVVSiRPVInputFlagArt33 {
  Item0 = 0,
  Item1 = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum MVVSiRPVInputFlagArt29 {
  Item0 = 0,
  Item1 = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum MVVSiRPVInputOraTrasp {
  Item00 = 0,
  Item01 = 1,
  Item02 = 2,
  Item03 = 3,
  Item04 = 4,
  Item05 = 5,
  Item06 = 6,
  Item07 = 7,
  Item08 = 8,
  Item09 = 9,
  Item10 = 10,
  Item11 = 11,
  Item12 = 12,
  Item13 = 13,
  Item14 = 14,
  Item15 = 15,
  Item16 = 16,
  Item17 = 17,
  Item18 = 18,
  Item19 = 19,
  Item20 = 20,
  Item21 = 21,
  Item22 = 22,
  Item23 = 23,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum MVVSiRPVInputMinutiTrasp {
  Item00 = 0,
  Item01 = 1,
  Item02 = 2,
  Item03 = 3,
  Item04 = 4,
  Item05 = 5,
  Item06 = 6,
  Item07 = 7,
  Item08 = 8,
  Item09 = 9,
  Item10 = 10,
  Item11 = 11,
  Item12 = 12,
  Item13 = 13,
  Item14 = 14,
  Item15 = 15,
  Item16 = 16,
  Item17 = 17,
  Item18 = 18,
  Item19 = 19,
  Item20 = 20,
  Item21 = 21,
  Item22 = 22,
  Item23 = 23,
  Item24 = 24,
  Item25 = 25,
  Item26 = 26,
  Item27 = 27,
  Item28 = 28,
  Item29 = 29,
  Item30 = 30,
  Item31 = 31,
  Item32 = 32,
  Item33 = 33,
  Item34 = 34,
  Item35 = 35,
  Item36 = 36,
  Item37 = 37,
  Item38 = 38,
  Item39 = 39,
  Item40 = 40,
  Item41 = 41,
  Item42 = 42,
  Item43 = 43,
  Item44 = 44,
  Item45 = 45,
  Item46 = 46,
  Item47 = 47,
  Item48 = 48,
  Item49 = 49,
  Item50 = 50,
  Item51 = 51,
  Item52 = 52,
  Item53 = 53,
  Item54 = 54,
  Item55 = 55,
  Item56 = 56,
  Item57 = 57,
  Item58 = 58,
  Item59 = 59,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum VisMVVSiRPVInputStatoVal {
  Item02 = 0,
  Item03 = 1,
  Item04 = 2,
  Item05 = 3,
  Item06 = 4,
  Item07 = 5,
  Item08 = 6,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum InsRicMvvSiRPVInputStatoVal {
  Item06 = 0,
  Item07 = 1,
  Item08 = 2,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum VisMVVDestSiRPVInputStatoVal {
  Item03 = 0,
  Item06 = 1,
  Item07 = 2,
  Item08 = 3,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum TipoRichiesta {
  I = 0,
  A = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum VisMVVODCSiRPVInputStatoVal {
  Item03 = 0,
  Item06 = 1,
  Item07 = 2,
  Item08 = 3,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum VisMVVODCSiRPVInputFlagEstero {
  Item0 = 0,
  Item1 = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum VisOperAttiveOdcSiRPVInputStatoOper {
  V = 0,
  A = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum Metodo {
  CH = 0,
  CL = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum Scorporo {
  S = 0,
  N = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum StatoDichiarazione {
  L = 0,
  T = 1,
  A = 2,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum DichiarazioneArricchimento {
  M = 0,
  D = 1,
  N = 2,
  A = 3,
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.WsMipaaf\Connected Services\wsRegVino\Reference.cs
export enum EndpointConfiguration {
  wsRegVino = 0,
}

// ..\webapi\src\FTM.Cloud.Agro\FTM.Cloud.Agro.NoSql\Model\MVVBase.cs
export interface IMipaafXMLConConvertible {}

// ..\webapi\src\FTM.Cloud.Agro\FTM.Cloud.Agro.NoSql\Model\MVVBase.cs
export interface MVVBase<T> extends IMipaafXMLConConvertible /*, INoSqlSecurityEntity*/ {
  _id?: string;
  id: string;
  azienda: number;
  sede: number;
  ownerAccount: string;
  tipo: TipoMVV;
  idDocOri: string;
  dataDocOri?: string;
  numDocOri: string;
  sezDocOri: string;
  ownerCodIcqrf: string;
  marcaTemp: string;
  numMVV: string;
  stato: string;
  pdf: string;
  dataPresentazione?: string;
  dataValidazione?: string;
  dataRicezione?: string;
  noteRicezione: string;
  enableAutoSync: boolean;
  datiMVV: T;
  updatedOn: string;
  lastModified?: string;
  flagEmergenza?: boolean;
  flagPecEmerg?: boolean;
  dataTraspMvvCartaceo?: string;
  numMvvCartaceo?: string;
  hash: number;
}

// ..\webapi\src\FTM.Cloud.Agro\FTM.Cloud.Agro.NoSql\Model\MVVBase.cs
export interface MapProfile /*extends Profile */ {}

// ..\webapi\src\FTM.Cloud.Agro\FTM.Cloud.Agro.NoSql\Model\MVVBase.cs
export interface MVV_STATI {}

// ..\webapi\src\FTM.Cloud.Agro\FTM.Cloud.Agro.NoSql\Model\MVVBase.cs
export enum TipoMVV {
  Ricevuto = 0,
  Emesso = 1,
}

// ..\webapi\src\FTM.Cloud.Agro\FTM.Cloud.Agro.Services\ControllersFlussi\DTO\WsAnnullaMvvRetDTO.cs
export interface WsAnnullaMvvRetDTO /* extends BaseDTO */ {
  esito: Response;
  mvv: MVVBase<MVVSiRPVInput>;
}

// ..\webapi\src\FTM.Cloud.Agro\FTM.Cloud.Agro.Services\ControllersMVV\DTO\MvvSyncDTO.cs
export interface MvvSyncDTO /*extends BaseDTO*/ {
  sync: StatoMvvMipaff;
  mvv: MVVBase<MVVSiRPVInput>;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\NoSql\TerminatedTaskList.cs
export interface DtoOutTeminatedTaskList {
  _id: string;
  retry: number;
  updatedOn?: string;
  operationId: string;
  senderInfoName: string;
  error: LogException;
  createdOn: string;
  state: ProcessingState;
  task: Operation;
  requestedEntity: Entity;
}

// ..\webapi\src\FTM.Cloud.Agro\Cloud.Agro.DTO\NoSql\TerminatedTaskList.cs
export interface DtoOutTotal {
  total: number;
}

// ..\ftm_core_libraries\ftm-core-be\FTM.Cloud.Core\Cloud.Core.DTO\ServiceBus\Enums.cs
export interface Extensions {}

// ..\ftm_core_libraries\ftm-core-be\FTM.Cloud.Core\Cloud.Core.DTO\ServiceBus\Enums.cs
export enum ProcessingState {
  New = 0,
  Sent = 1,
  InProgress = 10,
  Finish = 100,
  Error = 999,
}

// ..\ftm_core_libraries\ftm-core-be\FTM.Cloud.Core\Cloud.Core.DTO\ServiceBus\Enums.cs
export enum Operation {
  Sync = 0,
  UpdateFromMipaaf = 1,
  PublishToMipaaf = 2,
}

// ..\ftm_core_libraries\ftm-core-be\FTM.Cloud.Core\Cloud.Core.DTO\ServiceBus\Enums.cs
export enum Entity {
  Cataloghi = 0,
  Dopigp = 1,
  Ebacchus = 2,
  Giacenze = 3,
  Menzioni = 4,
  Mvv = 5,
  Soggetti = 6,
  Sottozone = 7,
  Varieta = 8,
  Vasi = 9,
  Vigne = 10,
  Zone = 11,
  MvvRicevuti = 12,
}

// ..\ftm_core_libraries\ftm-core-be\FTM.Cloud.Core\Cloud.Core.DTO\NoSql\LogException.cs
export interface LogException {
  message: string;
  stackTrace: string;
  source: string;
}
