import { Entities } from "../../entityDalFactory";
import { CommonConstants } from "../costants/common.constants";

export const sendRequest = (entity: Entities) => ({
  type: CommonConstants(entity).SendRequest,
});

export const requestOK = (entity: Entities) => ({
  type: CommonConstants(entity).RequestOk,
});

export const requestKO = (entity: Entities) => ({
  type: CommonConstants(entity).RequestKO,
});