/** Entities list on which can be created a DAL object */
export enum Entities {
  // General controllers and anagraph
  Auth = 'Auth',
  Menu = 'Menu',
  Accounts = 'Accounts',
  Sedevino = 'Sedevino',
  Anagvigne = 'Anagvigne',
  Cities = 'Cities',
  Countries = 'Countries',
  AnAnagen = 'AnaGen',
  Company = 'Company',
  Currency = 'Currency',
  Soggetto = 'Soggetto',
  SoggettiLookup = 'SoggettiLookup',
  SoggettoMaster = 'Soggetto/Master',
  TrasportatorioMaster = 'Trasportatorio/Master',
  Anagdestinazioni = 'Anagdestinazioni',
  Anagvasche = 'Anagvasche',
  Dashboard = 'Dashboard',
  Tipovaso = 'Tipovaso',

  // Cataloghi
  Cataloghi = 'Cataloghi',
  Dopigp = 'Dopigp',
  SottoZona = 'SottoZona',
  Ebacchus = 'Ebacchus',
  Zona = 'Zona',
  Anagpartite = 'Anagpartite',
  Lnkprovenienza = 'Lnkprovenienza',
  Lnkpraticaeno = 'Lnkpraticaeno',
  Lnkcategoria = 'Lnkcategoria',
  Lnkmenzione = 'Lnkmenzione',
  Lnkvarieta = 'Lnkvarieta',
  Grpmenzione = 'Grpmenzione',
  Grpvarieta = 'Grpvarieta',
  Grpprovenienza = 'Grpprovenienza',
  Grppraticaeno = 'Grppraticaeno',
  Grpcategoria = 'Grpcategoria',
  Gruppomenzione = 'Gruppomenzione',
  Gruppopraticaeno = 'Gruppopraticaeno',
  Gruppovarieta = 'Gruppovarieta',
  Gruppoprovenienza = 'Gruppoprovenienza',
  Gruppocategoria = 'Gruppocategoria',
  Anno = 'Anno',
  Menzione = 'Menzione',
  Varieta = 'Varieta',
  PraticaEno = 'PraticaEno',
  Colore = 'Colore',
  Statofisico = 'Statofisico',
  Classificazione = 'Classificazione',
  Categoria = 'Categoria',
  AttoCert = 'AttoCert',
  Biologico = 'Biologico',
  Provenienza = 'Provenienza',

  //sincronizzazione NUOVE
  Syncronization = 'Sync',

  // MVV-E
  MVVBaseVisDettMVVDestSiRPVOutput = 'MVVBaseVisDettMVVDestSiRPVOutput',
  MVVBaseMVVSiRPVInput = 'MVVBaseMVVSiRPVInput',
  Mvve = 'Mvve',
  Vi02DMatCatMvv = 'Vi02DMatCatMvv',
  Nomenclatura = 'Nomenclatura',
  TipoProdotto = 'TipoProdotto',
  CausaliTrasporto = 'CausaleTrasporto',
  MezziTrasporto = 'MezzoTrasporto',
  TenoreZuccherino = 'Tenorezucc',

  // From Schema - service
  FormSchemaEntries = 'FormSchemaEntries',

  // Registry - service
  Registri = 'RegistryOperations',
  OperSiRPV = 'OperSiRPV',

  // Pratiche Enologiche - service
  Communications = 'Communications',
  Dichiarazioni = 'Enrichments',
  CommunicationsType = 'CommunicationsType',
  MipaafCompliance = 'MipaafCompliance',
  MipaafEnrichment = 'MipaafEnrichment',
}

/** Filter operation used inside @interface ILookupFilterDTO for operation field */
export enum IWebFilterOperation {
  equals = 'equals',
  contains = 'contains',
  startWith = 'startwith',
  endswith = 'endswith',
  greaterThan = 'greaterthan',
  lessThan = 'lessthan',
  rangeDate = 'rangedate',
}

/** Enum used to specifies which operation should be enabled inside the entityDalFactory */
export enum GenericDalOperation {
  READ_FILTER = 0x1,
  READ_BY_ID = 0x2,
  CREATE = 0x4,
  UPDATE = 0x8,
  DELETE = 0x16,
  READ = READ_BY_ID | READ_FILTER,
  CR = READ | CREATE,
  RU = READ | UPDATE,
  CRU = CR | UPDATE,
  RUD = RU | DELETE,
  CRD = CR | DELETE,
  CRUD = CRU | DELETE,
  CRUD1 = DELETE,
}

export enum httpRequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

/** HTTP Response Status CODE list */
export enum HTTPStatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  Processing = 102,
  EarlyHints = 103,

  /**
   * All `1xx` status codes.
   */
  InformationalResponses = Continue | SwitchingProtocols | Processing | EarlyHints,

  OK = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultiStatus = 207,
  AlreadyReported = 208,
  IMUsed = 226,

  /**
   * All `2xx` status codes.
   */
  Success = OK |
    Created |
    Accepted |
    NonAuthoritativeInformation |
    NoContent |
    ResetContent |
    PartialContent |
    MultiStatus |
    AlreadyReported |
    IMUsed,

  MultipleChoices = 300,
  MovedPermanently = 301,
  Found = 302,
  SeeOther = 303,
  NotModified = 304,
  UseProxy = 305,
  SwitchProxy = 306,
  TemporaryRedirect = 307,
  PermanentRedirect = 308,

  /**
   * All `3xx` status codes.
   */
  Redirection = MultipleChoices |
    MovedPermanently |
    Found |
    SeeOther |
    NotModified |
    UseProxy |
    SwitchProxy |
    TemporaryRedirect |
    PermanentRedirect,

  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  PayloadTooLarge = 413,
  URITooLong = 414,
  UnsupportedMediaType = 415,
  RangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  ImATeapot = 418,
  MisdirectedRequest = 421,
  UnprocessableEntity = 422,
  Locked = 423,
  FailedDependency = 424,
  UpgradeRequired = 426,
  PreconditionRequired = 428,
  TooManyRequests = 429,
  RequestHeaderFieldsTooLarge = 431,
  UnavailableForLegalReasons = 451,

  /**
   * All `4xx` error codes.
   */
  ClientErrors = BadRequest |
    Unauthorized |
    PaymentRequired |
    Forbidden |
    NotFound |
    MethodNotAllowed |
    NotAcceptable |
    ProxyAuthenticationRequired |
    RequestTimeout |
    Conflict |
    Gone |
    LengthRequired |
    PreconditionFailed |
    PayloadTooLarge |
    URITooLong |
    UnsupportedMediaType |
    RangeNotSatisfiable |
    ExpectationFailed |
    ImATeapot |
    MisdirectedRequest |
    UnprocessableEntity |
    Locked |
    FailedDependency |
    UpgradeRequired |
    PreconditionRequired |
    TooManyRequests |
    RequestHeaderFieldsTooLarge |
    UnavailableForLegalReasons,

  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HTTPVersionNotSupported = 505,
  VariantAlsoNegotiates = 506,
  InsufficientStorage = 507,
  LoopDetected = 508,
  NotExtended = 510,
  NetworkAuthenticationRequired = 511,

  /**
   * All `5xx` error codes.
   */
  ServerErrors = InternalServerError |
    NotImplemented |
    BadGateway |
    ServiceUnavailable |
    GatewayTimeout |
    HTTPVersionNotSupported |
    VariantAlsoNegotiates |
    InsufficientStorage |
    LoopDetected |
    NotExtended |
    NetworkAuthenticationRequired,
}
