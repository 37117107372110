import { useEffect, useState } from 'react';
import { IDigitalMessage } from '../../core-component/TSDigitalSSO/sso.interfaces';
import { TokenProvider } from './tokenProvider';
import { ILoginUserToken, ITokenData } from './tokenProvider.interfaces';

const createAuthProvider = () => {
  const tp = TokenProvider.getInstance();
  
  /** Execute login, save user token on local cache and set Axios interceptor for refreshing expired tokens */
  const login = (newTokens: ILoginUserToken, tsDigitalData?: IDigitalMessage) => {
    tp.setToken(newTokens, tsDigitalData);
  };

  /** Execute logout, delete saved auth token from local cache */
  const logout = (removeTsDigitalSSOData: boolean = false) => {
    tp.setToken(null, removeTsDigitalSSOData ? null : undefined);
  };

  /** React custom Hook that grant access to login information inside funcion component */
  const useAuth = () => {
    const [isLogged, setIsLogged] = useState<boolean>(tp.isLoggedIn());
    const [tokenData, setTokenData] = useState<ITokenData | undefined>(tp.getUserData());

    useEffect(() => {
      const listener = (newIsLogged: boolean, tokenData: ITokenData | undefined) => {
        setIsLogged(newIsLogged);
        setTokenData(tokenData);
      };
      tp.subscribe(listener);
      return () => {
        tp.unsubscribe(listener);
      };
    }, []);

    return [isLogged, tokenData] as [typeof isLogged, typeof tokenData];
  };

  /** Access to decoded user data for class and functions outside React Component */
  const loggedUserData = () => tp.getUserData();

  /** Access to JWT Token */
  const jwtToken = async () => await tp.getToken();

  return { useAuth, login, logout, loggedUserData, jwtToken };
};

export const authProvider = createAuthProvider();