import { IReduxAction } from '../reduxStore.interfaces';
import { GridConstants } from '../costants/grid.constants';
import { IGridActionsPayload } from '../interfaces/grid.interfaces';
import { ICommonState } from '../interfaces/common.interfaces';
import { Entities } from '../../entityDalFactory';

type Actions = keyof typeof GridConstants;

export const commonReducer = <T>(entity: Entities) => (
  state: ICommonState<T> = { rows: [] },
  action: IReduxAction<Actions, IGridActionsPayload<T>>
): ICommonState<T> => {
  const actionEntity = GridConstants(entity);
  switch (action.type) {
    case actionEntity.GridSetData:
      return { ...state, rows: action.payload.rows };
    default:
      return state;
  }
};
