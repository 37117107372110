import React, { FC } from 'react';
import { Vploading } from '@agrocloud/react-vapor-component/vploading';
import './tsdigitalsso.less';

export const TSDigitalSSO: FC = () => {
  return (
    <div className="tsdigitalsso">
      <Vploading neutralmode/>
    </div>
  );
};