import React, { lazy, Suspense } from 'react';
import { ApplicationRouter } from './core-component/ApplicationRouter';
import './styles/index.less';
import { Spin } from 'antd';
const VpGlobalStyle = lazy(() => import('./core-component/VaporComponents/VpGlobalStyle'));

function App() {
  return (
    <>
      <Suspense fallback={<div className="main-loading"><Spin size="large"/></div>}>
        <VpGlobalStyle />
        <ApplicationRouter />
      </Suspense>
    </>
  );
}

export default App;
