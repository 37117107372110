import { Entities } from '../../entityDalFactory';
import { GridConstants, initialGridState } from './grid.constants';
import { RegistryOperation } from '../../interfaces/web-api-registry';
import { IOperazioniRegistroState } from '../interfaces/registri.interfaces';

export const RegistriConstants = {
  ...GridConstants(Entities.Registri),
};

export const initialRegistriState = {
  ...initialGridState<RegistryOperation>(),
};

export const initialFormSchemaState: IOperazioniRegistroState = {
  operazioni: [],
  isError: false,
  loading: false,
};

export const formSchemaConstants = {
  FETCH_FORM_SCHEMA_REQ: 'FETCH_FORM_SCHEMA_REQ',
  FETCH_FORM_SCHEMA_OK: 'FETCH_FORM_SCHEMA_OK',
  FETCH_FORM_SCHEMA_KO: 'FETCH_FORM_SCHEMA_KO',
};
