import { IAuthState } from "../interfaces/auth.interfaces";
import { initialLoadingStatus } from "../reduxStore.constants";
import { Entities } from "../../entityDalFactory";
import { CommonConstants } from "./common.constants";

export const AuthConstants = {
  Login: `${Entities.Auth}_Login`,
  Logout: `${Entities.Auth}_Logout`,
  SetCurrentSede: `${Entities.Auth}_SetCurrentSede`,
  SetCurrentCompany: `${Entities.Auth}_SetCurrentCompany`,
  DisableSwitchSede: `${Entities.Auth}_DisableSwitchSede`,
  ...CommonConstants(Entities.Auth)
}

export const initialState: IAuthState = {
  // loggedUserData: undefined,
  sediList: undefined,
  // userAccount: undefined,
  selectedCompany: undefined,
  selectedSede: undefined,
  requestStatus: { ...initialLoadingStatus },
};