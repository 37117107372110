import { ILoginUserToken, ITokenData } from './tokenProvider.interfaces';
import { TokenDecoder } from './TokenDecoder';
import Axios, { AxiosRequestConfig } from 'axios';
import { localStorageTokenKey, localStorageTSDigitalData, webApiBaseUrl } from '../constants/app';
import { LoginDTO } from '../interfaces/web-api';
import { Mutex } from 'async-mutex';
import { IDigitalMessage } from '../../core-component/TSDigitalSSO/sso.interfaces';

export class TokenProvider {
  private privateToken: ILoginUserToken | null;
  private listeners: Array<(newLogged: boolean, tokenData: ITokenData | undefined) => void> = [];
  private interceptors: { id?: number } = { id: undefined };
  private readonly mutex: Mutex = new Mutex();

  private static _instance?: TokenProvider;

  private constructor() {
    const savedTokenData = localStorage.getItem(localStorageTokenKey);
    this.privateToken = savedTokenData && JSON.parse(savedTokenData);
    if (savedTokenData) this.setAxiosInterceptor();
  }

  public static getInstance(): TokenProvider {
    if (!this._instance) this._instance = new TokenProvider();
    return this._instance;
  }

  private checkExpiredToken = (tok?: string): boolean => {
    if (!tok) return true;
    const token = new TokenDecoder(tok).token;
    if (!token) return true;
    const now = Math.round(new Date().getTime() / 1000);
    return token.exp <= now;
  };

  private setAxiosInterceptor = (): void => {
    this.interceptors.id = Axios.interceptors.request.use(
      (request) => this.handleOnFullfillRequest(request),
      (error) => this.handleOnRejectRequest(error)
    );
  };

  private removeAxiosInterceptor = (): void => {
    if (this.interceptors.id) {
      Axios.interceptors.request.eject(this.interceptors.id);
      this.interceptors.id = undefined;
    }
  };

  private handleOnFullfillRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const release = await this.mutex.acquire();
    try {
      const validToken = await this.getToken();
      config.headers.Authorization = `Bearer ${validToken?.token}`;
    } finally {
      release();
    }
    return config;
  };

  private handleOnRejectRequest = (error: any) => {
    return Promise.reject(error);
  };

  private notify = (): void => {
    const isLogged = this.isLoggedIn();
    const tokenData = this.getUserData();
    this.listeners.forEach((l) => l(isLogged, tokenData));
  };

  private async refreshToken(data: ILoginUserToken): Promise<ILoginUserToken | null> {
    try {
      const result = await Axios.create().post<LoginDTO>(`${webApiBaseUrl}/auth/refresh`, data);
      if (!result.data.token || !result.data.refreshToken) return null;
      return { token: result.data.token, refreshToken: result.data.refreshToken };
    } catch (err) {
      return null;
    }
  }

  private checkExpiry = async (): Promise<void> => {
    if (this.privateToken && this.checkExpiredToken(this.privateToken.token)) {
      const newToken = await this.refreshToken(this.privateToken);
      if (newToken) {
        this.setToken(newToken);
        console.debug('token refreshed');
      } else {
        this.setToken(null);
      }
    }
  };

  public getToken = async (): Promise<ILoginUserToken | null> => {
    await this.checkExpiry();
    return this.privateToken;
  };

  public isLoggedIn = (): boolean => {
    return !!this.privateToken;
  };

  public getUserData = (): ITokenData | undefined => {
    if (!this.privateToken && !this.isLoggedIn()) return undefined;
    return new TokenDecoder(this.privateToken?.token).DecodedData;
  };

  public setToken = (token: ILoginUserToken | null, tsDigitalData?: IDigitalMessage | null): void => {
    // 1. If set, try to remove Axios interceptors
    this.removeAxiosInterceptor();

    // 2. Check if new token was provided
    if (token) {
      // Yes => Save token to local cache and set new axios interceptors
      localStorage.setItem(localStorageTokenKey, JSON.stringify(token));
      this.setAxiosInterceptor();
      // if given we manage on localStorage the tsDigitalData message for SSO login
      if (tsDigitalData) localStorage.setItem(localStorageTSDigitalData, JSON.stringify(tsDigitalData));
    } else {
      // Token is null or undefined => remove JWT token from local cacha (if any)
      localStorage.removeItem(localStorageTokenKey);

      // Remove all the localStorage data, so we try to remove tsDigitalData as well
      if (tsDigitalData === null) localStorage.removeItem(localStorageTSDigitalData);
    }

    // 3. Save token on prop and notify component from the update
    this.privateToken = token;
    this.notify();
  };

  public subscribe = (listener: (logged: boolean, tokenData: ITokenData | undefined) => void) => {
    this.listeners.push(listener);
  };

  public unsubscribe = (listener: (logged: boolean, tokenData: ITokenData | undefined) => void) => {
    this.listeners = this.listeners.filter((l) => l !== listener);
  };
}
