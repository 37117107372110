import { IReduxAction } from '../reduxStore.interfaces';
import { GridConstants } from '../costants/grid.constants';
import { IGridActionsPayload } from '../interfaces/grid.interfaces';
import { RegistryOperation } from '../../interfaces/web-api-registry';
import { RegistriConstants, initialRegistriState, formSchemaConstants, initialFormSchemaState } from '../costants/registri.constants';
import { IOperazioniRegistroState, IRegistriState } from '../interfaces/registri.interfaces';

type Actions = keyof typeof GridConstants;

export const registriReducer = (
  state: IRegistriState = initialRegistriState,
  action: IReduxAction<Actions, IGridActionsPayload<RegistryOperation>>
): IRegistriState => {
  switch (action.type) {
    case RegistriConstants.SetFilters:
      return { ...state, filters: action.payload.filters };
    default:
      return state;
  }
};

export const formSchemaReducer = (
  state: IOperazioniRegistroState = initialFormSchemaState,
  action: IReduxAction<Actions, IOperazioniRegistroState>
): IOperazioniRegistroState => {
  switch (action.type) {
    case formSchemaConstants.FETCH_FORM_SCHEMA_REQ:
    case formSchemaConstants.FETCH_FORM_SCHEMA_KO:
    case formSchemaConstants.FETCH_FORM_SCHEMA_OK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
