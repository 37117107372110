import { LoginDTO, LoginResultDTO, UserCompaniesResponse } from '../../common/interfaces/web-api';
import Axios, { AxiosResponse } from 'axios';
import { webApiBaseUrl } from '../../common/constants';
import { IDigitalMessage, ResultLogin } from '../../core-component/TSDigitalSSO/sso.interfaces';

export const LoginDal = {
  login: async (data: LoginDTO) => {
    try {
      return (await Axios.post<LoginDTO, AxiosResponse<LoginResultDTO>>(`${webApiBaseUrl}/auth/login`, data)).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  ssoDigitalLogin: async (data: IDigitalMessage) => {
    // response is like this => {"token":"","refreshToken":"","sessionToken":null}
    try {
      return (
        await Axios.post<IDigitalMessage, AxiosResponse<ResultLogin>>(`${webApiBaseUrl}/TsDigital/validateuser`, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      ).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  getUserCompanies: async () => {
    try {
      return (await Axios.get<UserCompaniesResponse[]>(`${webApiBaseUrl}/AccountsForCompany/usercompanies`)).data;
    } catch (err) {
      throw err;
    }
  },
};
