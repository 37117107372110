import { AuthConstants } from '../costants/auth.constants';
import { IAuthActionsPayload } from '../interfaces/auth.interfaces';
import { ITokenData } from '../../authProvider';
import { Dispatch } from 'redux';
import { IDalReadFilter, entityDalFactory, GenericDalOperation, Entities, IDalCRUD } from '../../entityDalFactory';
import { Vw01SedisedevinoDTO, Vw01AziendaanagraficaDTO } from '../../interfaces/web-api';
import { sendRequest, requestKO, requestOK } from './common.actions';

// const userDal = entityDalFactory<An03AccountDTO, IDalReadById<An03AccountDTO>>({
//   enableOperation: GenericDalOperation.READ_BY_ID,
//   entityName: Entities.Accounts,
// });

const sedeVinoDal = entityDalFactory<Vw01SedisedevinoDTO, IDalReadFilter<Vw01SedisedevinoDTO>>({
  enableOperation: GenericDalOperation.READ_FILTER,
  entityName: Entities.Sedevino,
});

const companyDal = entityDalFactory<Vw01AziendaanagraficaDTO, IDalCRUD<Vw01AziendaanagraficaDTO>>({
  enableOperation: GenericDalOperation.CRUD,
  entityName: Entities.Company,
});

const updateUserData = (userData: IAuthActionsPayload) => ({
  type: AuthConstants.Login,
  payload: userData,
});

export const authActions = {
  login: (loggedUserData: ITokenData) => async (dispatch: Dispatch) => {
    dispatch(sendRequest(Entities.Auth));
    // const userResult = await userDal.getById(loggedUserData.nameid);
    // if (!userResult.data && userResult.error) {
    //   dispatch(requestKO(Entities.Auth));
    //   return;
    // }
    const sediResult = await sedeVinoDal.get({ pageNumber: 1, pageSize: 150 });
    if (!sediResult.data && sediResult.error && sediResult.error.httpStatusCode !== 404) {
      dispatch(requestKO(Entities.Auth));
      return;
    }

    const data = await companyDal.getById('');
    if (!data.data && data.error) {
      dispatch(requestKO(Entities.Auth));
      return;
    }
    dispatch(requestOK(Entities.Auth));
    dispatch(
      updateUserData({
        loggedUserData,
        /* userAccount: userResult.data!, */ sediList: sediResult.data?.data ?? [],
        selectedCompany: data.data,
      })
    );
  },
  logout: () => ({
    type: AuthConstants.Logout,
  }),
  setCurrentSede: (selectedSede: Vw01SedisedevinoDTO) => ({ type: AuthConstants.SetCurrentSede, payload: { selectedSede } }),
  setCurrentTenant: (selectedCompany: Vw01AziendaanagraficaDTO) => ({
    type: AuthConstants.SetCurrentCompany,
    payload: { selectedCompany },
  }),
  setDisableSwitchSede: (disableSwitchSede: boolean) => ({ type: AuthConstants.DisableSwitchSede, payload: { disableSwitchSede } }),
};
