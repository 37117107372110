import { IReduxAction } from '../reduxStore.interfaces';
import { VasiConstants as cnst } from '../costants/vasi.constants';
import { IVasiActionPayload, ITipoVasiState } from '../interfaces/vasi.interface';

export const vasiReducer = (
  state: ITipoVasiState = {
    vasioptions: [],
    isLoading: false,
    isError: false,
  },
  action: IReduxAction<cnst, IVasiActionPayload>
): ITipoVasiState => {
  switch (action.type) {
    case cnst.GET_VASI_REQ:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: undefined,
      };
    case cnst.GET_VASI_OK:
      return {
        ...state,
        isLoading: false,
        vasioptions: action.payload.tipoVasiData,
      };
    case cnst.GET_VASI_KO:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
};
