import { Entities } from '../../entityDalFactory';
import { GridConstants, initialGridState } from './grid.constants';
import { CatalogoInfoDTO } from '../../interfaces/web-api';
import { nameof } from '../../../utils/nameof';

export const CataloghiConstants = {
  ...GridConstants(Entities.Cataloghi),
};

export const initialCataloghiState = {
  ...initialGridState<CatalogoInfoDTO>(),
  hiddenColumns: [
    nameof<CatalogoInfoDTO>('vi33Massavol'),
    nameof<CatalogoInfoDTO>('vi33IdanaapartiteVi31'),
    nameof<CatalogoInfoDTO>('vi30Descrizione'),
    nameof<CatalogoInfoDTO>('menzioneList'),
    nameof<CatalogoInfoDTO>('vi17Descrizione'),
    nameof<CatalogoInfoDTO>('vi33Varietaman'),
    nameof<CatalogoInfoDTO>('co03Descr'),
  ],
};
