import React, { FC, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { authProvider } from '../../common/authProvider';
import { Login } from '../../screens/Login';
import { Whoops404 } from '../../screens/NotFound';
import { useDigitalPortalSSO } from '../TSDigitalSSO/useDigitalPortalSSO';
import { TSDigitalSSO } from '../TSDigitalSSO';
import { Spin } from 'antd';
import { useMountEffect } from '../../common/componentFunctions/useMountEffect';
import { TsLocaleProvider, TsTranslationsProvider } from '@tse-core/ts-components/lib/provider';

const TSDigitalVaporUishell = lazy(() => import('../TSDigitalPortalUiShell'));
const CompanySwitchTSDigitalSSO = lazy(() => import('../../screens/CompanySwitch/CompanySwitchTSDigitalSSO'));

const { useAuth, logout } = authProvider;

const BaseLoginSSO: FC = () => {
  const [isLoggedBySSO, tsDigitalLoginData, refreshLogin] = useDigitalPortalSSO();
  const history = useHistory();

  useEffect(() => {
    if (isLoggedBySSO) {
      history.push('/');
    } else if (tsDigitalLoginData) {
      refreshLogin();
    }
  }, [isLoggedBySSO, tsDigitalLoginData, refreshLogin, history]);

  return <TSDigitalSSO />;
};

const RenderLazyComponent: FC<{ component: any }> = (props) => {
  const { component: LazyComponent } = props;
  return (
    <Suspense
      fallback={
        <div className="main-loading">
          <Spin size="large" />
        </div>
      }
    >
      <LazyComponent />
    </Suspense>
  );
};

export const ApplicationRouter: React.FC = () => {
  const [isLogged] = useAuth();
  useMountEffect(() => {
    // it's used for cancel token for first access in SSO cases
    if (process.env.REACT_APP_ENABLE_DEVELOPMENT === 'false') {
      logout(true);
    }
  });

  return (
    <TsLocaleProvider language="it" locale="it_IT">
      <TsTranslationsProvider language="it" translations={{}}>
        <BrowserRouter>
          <Router>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/tsdigitalsso" component={BaseLoginSSO} />
              <Route path="/404" component={Whoops404} />
              <Route path="/companyswitch" render={() => <RenderLazyComponent component={CompanySwitchTSDigitalSSO} />} />
              {isLogged && <Route path="/" render={() => <RenderLazyComponent component={TSDigitalVaporUishell} />} />}
              {!isLogged && <Redirect to="/tsdigitalsso" />}
            </Switch>
          </Router>
        </BrowserRouter>
      </TsTranslationsProvider>
    </TsLocaleProvider>
  );
};
