/**
 * It contains a set of constants for login page
 */
export const loginUIConstants = {
  /**
   * Text about Teamsystem ID definition
   */
  teamsystemIDText:
    "Con il TeamSystem ID puoi accedere alle varie applicazioni TeamSystem usando la tua email e password. In questo modo non dovrai più ricordare tante credenziali di accesso per diverse applicazioni. In più, con Teamsystem ID non hai bisogno di effettuare logout e un nuovo login per passare da una applicazione all'altra, ma puoi rimanere connesso. Vorresti provare il Teamsystem ID? Registrati e associa il Teamsystem ID alle credenziali che utilizzi normalmente per ciascuna applicazione solo al primo login. Gli utenti di un domnio Teamsystem hanno già un Tesamsystem ID.",
};
