import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { authReducer } from './reducers/auth.reducer';
import { persistStoreConfiguration } from './persistStoreConfiguration';
import { authProvider } from '../authProvider';
import { IReduxStore } from './reduxStore.interfaces';
import { initialLoadingStatus } from './reduxStore.constants';
import { IAuthState } from './interfaces/auth.interfaces';
import { cataloghiReducer } from './reducers/cataloghi.reducer';
import { formSchemaReducer, registriReducer } from './reducers/registri.reducer';
import { commonReducer } from './reducers/common.reducer';
import {
  Vi07MenzioneDTO,
  Vi09VarietaDTO,
  Vi03PraticaenoDTO,
  Vi06ColoreDTO,
  Vi05ClassificazioneDTO,
  Vi12StatofisicoDTO,
  Vi04ZonaDTO,
  Vi18SottozonaDTO,
  Vi02CategoriaDTO,
  Vi16AttocertDTO,
  Vi15BiologicoDTO,
  Vi10ProvenienzaDTO,
  Co03TabstatiestDTO,
  Vi43TipoprodottoDTO,
  Vi45CausaletrasportoDTO,
  Vi46MezzotrasportoDTO,
  Vi47NomenclaturaDTO,
  Vi11TenorezuccDTO,
  Co07AnnoDTO,
} from '../interfaces/web-api';
import { Entities } from '../entityDalFactory';
import { mvveReducer } from './reducers/mvve.reducer';
import { vasiReducer } from './reducers/vasi.reducer';
import { praticheReducers } from './reducers/pratiche.reducers';

/**
 * This global interface was added to handle the REDUX DEV TOOLS functions.
 */
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

declare let window: any;

export const appReducers = combineReducers<IReduxStore>({
  auth: authReducer,
  cataloghi: cataloghiReducer,
  registri: registriReducer,
  menzioni: commonReducer<Vi07MenzioneDTO>(Entities.Menzione),
  varieta: commonReducer<Vi09VarietaDTO>(Entities.Varieta),
  praticaeno: commonReducer<Vi03PraticaenoDTO>(Entities.PraticaEno),
  colore: commonReducer<Vi06ColoreDTO>(Entities.Colore),
  classificazione: commonReducer<Vi05ClassificazioneDTO>(Entities.Classificazione),
  statofisico: commonReducer<Vi12StatofisicoDTO>(Entities.Statofisico),
  zone: commonReducer<Vi04ZonaDTO>(Entities.Zona),
  sottozone: commonReducer<Vi18SottozonaDTO>(Entities.SottoZona),
  categorie: commonReducer<Vi02CategoriaDTO>(Entities.Categoria),
  attocert: commonReducer<Vi16AttocertDTO>(Entities.AttoCert),
  biologico: commonReducer<Vi15BiologicoDTO>(Entities.Biologico),
  provenienza: commonReducer<Vi10ProvenienzaDTO>(Entities.Provenienza),
  statiesteri: commonReducer<Co03TabstatiestDTO>(Entities.Countries),
  annate: commonReducer<Co07AnnoDTO>(Entities.Anno),
  tipoprodotto: commonReducer<Vi43TipoprodottoDTO>(Entities.TipoProdotto),
  causaletrasporto: commonReducer<Vi45CausaletrasportoDTO>(Entities.CausaliTrasporto),
  mezzotrasporto: commonReducer<Vi46MezzotrasportoDTO>(Entities.MezziTrasporto),
  nomenclatura: commonReducer<Vi47NomenclaturaDTO>(Entities.Nomenclatura),
  tenorezucch: commonReducer<Vi11TenorezuccDTO>(Entities.TenoreZuccherino),
  mvve: mvveReducer,
  operazioniRegistro: formSchemaReducer,
  tipovasi: vasiReducer,
  pratiche: praticheReducers,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';
const persistedReducer = persistReducer(persistStoreConfiguration.persistConfig, appReducers);

const getInitialState = async (): Promise<Partial<IReduxStore>> => {
  const auth: IAuthState = {
    loggedUserData: authProvider.loggedUserData(),
    sediList: undefined,
    requestStatus: { ...initialLoadingStatus },
  };
  return {
    auth,
  };
};

const createApplicationStore = async (): Promise<[Store<IReduxStore>, Persistor]> => {
  try {
    const initialState = await getInitialState();
    const store = createStore(
      persistedReducer,
      initialState,
      enableDevTools
        ? composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<any, any>))
        : applyMiddleware(thunk as ThunkMiddleware<any, any>)
    );
    const persistor = persistStore(store);
    if (enableDevTools) console.log('ReduxDevTool is enable');
    return [store, persistor];
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/**
 * This class is a singleton class that create and initialize redux store
 * with initial state just only once and share the same instance of that
 */
export class ReduxStoreSingleton {
  private static _instance?: ReduxStoreSingleton;
  private _store?: [Store<IReduxStore>, Persistor];

  public get store(): Store<IReduxStore> | undefined {
    return this._store ? this._store[0] : undefined;
  }

  public get persistor(): Persistor | undefined {
    return this._store ? this._store[1] : undefined;
  }

  // private constructor() {}

  public static async getInstance(): Promise<ReduxStoreSingleton> {
    if (!this._instance) this._instance = new ReduxStoreSingleton();
    if (!this._instance._store) this._instance._store = await createApplicationStore();
    return this._instance;
  }
}
