import { IReduxAction } from '../reduxStore.interfaces';
import { GridConstants } from '../costants/grid.constants';
import { IGridActionsPayload } from '../interfaces/grid.interfaces';
import { CatalogoInfoDTO } from '../../interfaces/web-api';
import { CataloghiConstants, initialCataloghiState } from '../costants/cataloghi.constants';
import { ICataloghiState } from '../interfaces/cataloghi.interfaces';

type Actions = keyof typeof GridConstants;

export const cataloghiReducer = (
  state: ICataloghiState = initialCataloghiState,
  action: IReduxAction<Actions, IGridActionsPayload<CatalogoInfoDTO>>
): ICataloghiState => {
  switch (action.type) {
    case CataloghiConstants.SetFilters:
      if (state?.filters?.filters.length != action.payload.filters.filters.length) {
        action.payload.filters.pageIndex = 0;
      }
      return { ...state, filters: action.payload.filters };
    case CataloghiConstants.SetHiddenColumns:
      return { ...state, hiddenColumns: action.payload.hiddenColumns };
    default:
      return state;
  }
};
