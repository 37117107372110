import { EnoPracticeCommunication } from '../../interfaces/web-api-pratiche-eno';
import { praticheActionType } from '../costants/pratiche.constants';
import { IGridActionsPayload } from '../interfaces/grid.interfaces';
import { IPraticheGridState } from '../interfaces/pratiche.interfaces';
import { IReduxAction } from '../reduxStore.interfaces';

export const praticheReducers = (
  state: IPraticheGridState = { rows: [] },
  action: IReduxAction<string, IGridActionsPayload<EnoPracticeCommunication>>
): IPraticheGridState => {
  switch (action.type) {
    case praticheActionType.SetFilters:
      return { ...state, filters: action.payload.filters };
    default:
      return state;
  }
};
