import { ParentApp } from '@mondora/microfrontends';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { authProvider, ILoginUserToken } from '../../common/authProvider';
import { localStorageTSDigitalData, tsDigitalPortalUrl } from '../../common/constants';
import { LoginDal } from '../../screens/Login/Login.dal';
import { IDigitalMessage } from './sso.interfaces';

export function useDigitalPortalSSO() {
  const [isLoggedBySSO, setIsLoggedBySSO] = useState<boolean>();
  const [portalLoginData, setportalLoginData] = useState<IDigitalMessage>();

  const ssoLogin = async (data: IDigitalMessage) => {
    try {
      const { securityToken, refreshToken } = await LoginDal.ssoDigitalLogin(data);
      authProvider.login({ token: securityToken, refreshToken } as ILoginUserToken, data);
      const isLogged: boolean = securityToken !== undefined && securityToken !== null && securityToken !== '';
      setIsLoggedBySSO(isLogged);
    } catch (err) {
      const e = err as AxiosError;
      console.error(e.message);
    }
  };

  const refreshLogin = async (): Promise<void> => portalLoginData && (await ssoLogin(portalLoginData));

  useEffect(() => {
    const parent = new ParentApp({
      onLaunch: (params: any) => {
        const newDataPayload = {
          data: {
            ...params,
          },
        } as IDigitalMessage;
        params && ssoLogin(newDataPayload);
      },
      parentOrigin: `${tsDigitalPortalUrl}`,
    });

    const savedDigitalDataStr = localStorage.getItem(localStorageTSDigitalData);
    setportalLoginData(savedDigitalDataStr && JSON.parse(savedDigitalDataStr));
  }, []);
  return [isLoggedBySSO, portalLoginData, refreshLogin] as [typeof isLoggedBySSO, typeof portalLoginData, () => Promise<void>];
}
