import update from 'immutability-helper';
import { AnyAction } from 'redux';
import {
  IMVVState,
  IWebApiSearchResultWithState,
  IMVVBaseExt,
  IWebServiceDecodedResponse,
  IMvvRowError,
} from '../interfaces/mvve.interfaces';
import { mvveConstants as cnst, mvvCommonDatas } from '../costants/mvve.constants';
import { appReduxConstants } from '../reduxStore.constants';
import { persistStoreConfiguration } from '../persistStoreConfiguration';
import { MVVSiRPVInput, VisDettMVVDestSiRPVOutput, TipoMVV } from '../../wsRegVino';

const { statiMVVE } = mvvCommonDatas;

const GRID_DEFAULT_PAGE_SIZE = 100;

const initialState: IMVVState = {
  statiMVVE,
  selectedMvv: undefined,
  detailFormValue: undefined,
  emittedMVV: {
    data: [],
    hasError: false,
    recordsOut: 0,
    totalPages: 0,
    totalRecords: 0,
    currentPage: 0,
    pageSize: GRID_DEFAULT_PAGE_SIZE,
    selectedRowId: undefined,
  } as IWebApiSearchResultWithState<IMVVBaseExt<MVVSiRPVInput>>,
  recivedMVV: {
    data: [],
    hasError: false,
    recordsOut: 0,
    totalPages: 0,
    totalRecords: 0,
    currentPage: 0,
    pageSize: GRID_DEFAULT_PAGE_SIZE,
    selectedRowId: undefined,
  } as IWebApiSearchResultWithState<IMVVBaseExt<VisDettMVVDestSiRPVOutput>>,
  loading: false,
  isError: false,
  errorMsg: undefined,
  lastRowError: undefined,
  selectedProduct: undefined,
  refreshMvveForm: undefined,
  selectedMvvDeleted: false,
  isFormModified: false,
  inputForm: undefined,
  admittedCategoryProduct: undefined,
};

/**
 * Basic reducer to handle MVV Dashboard *
 * @param {IMVVState=initialState} state
 * @param {AnyAction} action
 * @returns IMVVState
 */
export const mvveReducer = (state: IMVVState = initialState, action: AnyAction): IMVVState => {
  const { errorMsg, recivedMVV, emittedMVV, selectedMvv, categories, selectedProduct, refreshMvveForm, isModified, inputForm, Mvv } =
    action;
  switch (action.type) {
    case cnst.REFRESH_MVVE_REQ:
    case cnst.FETCH_MVVE_REQ:
      return {
        ...state,
        isError: false,
        loading: true,
        errorMsg: undefined,
        selectedMvv: undefined,
        inputForm: undefined,
        refreshMvveForm: undefined,
        selectedMvvDeleted: false,
      };
    case cnst.VALIDATE_MVVE_REQ:
    case cnst.SYNC_MVVE_REQ:
    case cnst.DELETE_MVVE_REQ:
    case cnst.ANNULL_MVVE_REQ:
    case cnst.REPLY_RECEIVED_MVV_REQ:
    case cnst.UPDATE_NOTE_MVV_REQ:
      return {
        ...state,
        isError: false,
        loading: true,
        errorMsg: undefined,
        refreshMvveForm: undefined,
        selectedMvvDeleted: false,
      };
    case cnst.FETCH_MVVE_OK:
      return {
        ...state,
        loading: false,
        emittedMVV: emittedMVV ? { ...emittedMVV } : { ...state.emittedMVV },
        recivedMVV: recivedMVV ? { ...recivedMVV } : { ...state.recivedMVV },
        selectedMvv,
        refreshMvveForm,
      };
    case cnst.SYNC_MVVE_OK:
      const syncMvvId = emittedMVV.data[0].Id;
      const idxSyncToRefresh = state.emittedMVV.data.findIndex((mvv) => mvv.id === syncMvvId);
      const newSyncEmittedMVV = update(state.emittedMVV, {
        data: { [idxSyncToRefresh]: { $set: emittedMVV.data[0] } },
      });
      return {
        ...state,
        loading: false,
        emittedMVV: newSyncEmittedMVV,
        recivedMVV: { ...state.recivedMVV },
        selectedMvv: state.selectedMvv ? emittedMVV.data[0] : undefined,
        refreshMvveForm,
      };
    case cnst.ANNULL_MVVE_OK:
      return {
        ...state,
        isError: false,
        loading: false,
        errorMsg: undefined,
        selectedMvv: state.selectedMvv ? Mvv : undefined,
        refreshMvveForm,
      };
    case cnst.REFRESH_MVVE_KO:
    case cnst.FETCH_MVVE_KO:
      return {
        ...state,
        isError: true,
        loading: false,
        errorMsg,
      };
    case cnst.ANNULL_MVVE_KO:
    case cnst.VALIDATE_MVVE_KO:
    case cnst.DELETE_MVVE_KO:
      const { lastRowError } = action;
      const { rowId, rowType, messaggio, codice, severity, decodedMessage } = lastRowError as IMvvRowError;

      if (!rowId)
        return {
          ...state,
          isError: true,
          loading: false,
          errorMsg: messaggio || '',
          refreshMvveForm,
        };

      const idxToSetError =
        rowType === TipoMVV.Emesso
          ? state.emittedMVV.data.findIndex((mvv) => mvv.id === rowId)
          : state.recivedMVV.data.findIndex((mvv) => mvv.id === rowId);

      const objToSubstitute = rowType === TipoMVV.Emesso ? state.emittedMVV.data[idxToSetError] : state.recivedMVV.data[idxToSetError];
      const rowToUpdate = {
        ...objToSubstitute,
        isError: true,
        errorDetails: { messaggio, codice, severity, decodedMessage } as IWebServiceDecodedResponse,
      };

      const newEmittedMVVWithError =
        rowType === TipoMVV.Emesso
          ? update(state.emittedMVV, {
              data: {
                [idxToSetError]: {
                  $set: rowToUpdate as IMVVBaseExt<MVVSiRPVInput>,
                },
              },
            })
          : emittedMVV;

      const newReceivedMVVWithError =
        rowType === TipoMVV.Ricevuto
          ? update(state.recivedMVV, {
              data: {
                [idxToSetError]: {
                  $set: rowToUpdate as IMVVBaseExt<VisDettMVVDestSiRPVOutput>,
                },
              },
            })
          : recivedMVV;

      return {
        ...state,
        loading: false,
        emittedMVV: newEmittedMVVWithError ? newEmittedMVVWithError : { ...state.emittedMVV },
        recivedMVV: newReceivedMVVWithError ? newReceivedMVVWithError : { ...state.recivedMVV },
        selectedMvv: state.selectedMvv
          ? {
              ...state.selectedMvv,
              stato: '04',
            }
          : undefined,
        refreshMvveForm,
      };
    case cnst.EDIT_MVVE:
      return {
        ...state,
        loading: false,
        emittedMVV: emittedMVV ? { ...state.emittedMVV, ...emittedMVV } : { ...state.emittedMVV },
        recivedMVV: recivedMVV ? { ...state.recivedMVV, ...recivedMVV } : { ...state.recivedMVV },
      };
    case cnst.RESET_SELECTED_MVVE:
      return {
        ...state,
        selectedMvv: undefined,
        selectedMvvDeleted: false,
        inputForm: undefined,
      };
    case cnst.REFRESH_MVVE_OK:
      const isMVVEmittedType: boolean = emittedMVV ? true : false;
      const mvvId = isMVVEmittedType ? emittedMVV.data[0].Id : recivedMVV.data[0].Id;

      const idxToSubstitute = isMVVEmittedType
        ? state.emittedMVV.data.findIndex((mvv) => mvv.id === mvvId)
        : state.recivedMVV.data.findIndex((mvv) => mvv.id === mvvId);

      const newEmittedMVV = isMVVEmittedType
        ? update(state.emittedMVV, {
            data: { [idxToSubstitute]: { $set: emittedMVV.data[0] } },
          })
        : emittedMVV;

      const newReceivedMVV = !isMVVEmittedType
        ? update(state.recivedMVV, {
            data: { [idxToSubstitute]: { $set: recivedMVV.data[0] } },
          })
        : recivedMVV;

      return {
        ...state,
        loading: false,
        emittedMVV: emittedMVV ? newEmittedMVV : { ...state.emittedMVV },
        recivedMVV: recivedMVV ? newReceivedMVV : { ...state.recivedMVV },
        // selectedMvv,
        refreshMvveForm,
      };
    case cnst.VALIDATE_MVVE_OK:
      const mvvValidatedId = emittedMVV.data[0].Id;
      const idxValidatedToRefresh = state.emittedMVV.data.findIndex((mvv) => mvv.id === mvvValidatedId);
      const newValidatedEmittedMVV = update(state.emittedMVV, {
        data: { [idxValidatedToRefresh]: { $set: emittedMVV.data[0] } },
      });

      return {
        ...state,
        loading: false,
        emittedMVV: newValidatedEmittedMVV,
        selectedMvv: state.selectedMvv ? emittedMVV.data[0] : undefined,
        refreshMvveForm,
      };
    case cnst.CREATE_MVV_PRODUCT:
    case cnst.SELECT_MVV_PRODUCT: {
      return {
        ...state,
        selectedProduct,
      };
    }
    case cnst.RESET_MVV_PRODUCT: {
      return {
        ...state,
        selectedMvv: state.selectedProduct ? state.selectedProduct.relatedMvv : undefined,
        selectedProduct: undefined,
      };
    }
    case cnst.DELETE_MVVE_OK:
      const isMVVTypeEmitted: boolean = emittedMVV ? true : false;
      const idMvv = isMVVTypeEmitted ? emittedMVV.data[0].Id : recivedMVV.data[0].Id;

      const idxToRemove = isMVVTypeEmitted
        ? state.emittedMVV.data.findIndex((mvv) => mvv.id === idMvv)
        : state.recivedMVV.data.findIndex((mvv) => mvv.id === idMvv);

      const removedEmittedMVV = isMVVTypeEmitted
        ? update(state.emittedMVV, {
            data: { $splice: [[idxToRemove, 1]] },
          })
        : emittedMVV;

      const removedReceivedMVV = !isMVVTypeEmitted
        ? update(state.recivedMVV, {
            data: { $splice: [[idxToRemove, 1]] },
          })
        : recivedMVV;

      return {
        ...state,
        loading: false,
        emittedMVV: emittedMVV ? removedEmittedMVV : { ...state.emittedMVV },
        recivedMVV: recivedMVV ? removedReceivedMVV : { ...state.recivedMVV },
        selectedMvv,
        selectedMvvDeleted: true,
      };
    case cnst.SET_FORM_MODIFIED:
      return {
        ...state,
        isFormModified: isModified,
      };
    case cnst.REPLY_RECEIVED_MVV_OK:
      return {
        ...state,
        loading: false,
        selectedMvv,
        refreshMvveForm,
      };
    case cnst.REPLY_RECEIVED_MVV_KO:
      return {
        ...state,
        loading: false,
        selectedMvv: state.selectedMvv ? { ...state.selectedMvv } : undefined,
      };
    case cnst.UPDATE_NOTE_MVV_OK:
    case cnst.UPDATE_NOTE_MVV_KO:
      return {
        ...state,
        loading: false,
        selectedMvv: state.selectedMvv ? { ...state.selectedMvv } : undefined,
      };
    case cnst.SAVE_MVVE_REDUX:
      return {
        ...state,
        selectedMvv,
      };
    case cnst.SAVE_FORM_REDUX:
      return {
        ...state,
        inputForm,
      };
    case cnst.FETCH_CATEGORY_FROM_PRODUCT_TYPE_REQ:
      return {
        ...state,
        loading: true,
        isError: false,
        errorMsg: undefined,
        admittedCategoryProduct: undefined,
      };
    case cnst.FETCH_CATEGORY_FROM_PRODUCT_TYPE_OK:
      return {
        ...state,
        loading: false,
        admittedCategoryProduct: categories,
      };
    case cnst.FETCH_CATEGORY_FROM_PRODUCT_TYPE_KO:
      return {
        ...state,
        loading: false,
        isError: true,
        errorMsg,
      };
    case cnst.DELETE_MESSAGE_ERROR:
      return {
        ...state,
        errorMsg: undefined,
      };
    case appReduxConstants.RESET_REDUX: {
      if (!persistStoreConfiguration.isPersisted('mvve')) {
        return { ...initialState };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};
