import { IStepPropsData } from '../../../core-component/StateViewer/stateViewer.interfaces';
import { IStatoMVVE } from '../interfaces/mvve.interfaces';

export enum TipoSoggettoMVVE {
  Destinatario = 1,
  Acquirente = 2,
  Committente = 3,
  Trasportatore = 4,
}

export enum MvveDocumentState {
  Bozza = 1,
  Presentato = 2,
  Validato = 3,
  Non_Validato = 4,
  Annullato = 5,
  Accettato = 6,
  Respinto = 7,
  Accettato_con_revisione = 8,
}

const BozzaState: IStepPropsData = { title: 'Bozza', description: 'Mvv in costruzione', status: 'finish' };
const ValidatoState: IStepPropsData = { title: 'Validato', description: 'MVV validato dal MIPAF', status: 'finish' };
const InAttesaDest: IStepPropsData = {
  title: 'In attesa destinatario',
  description: 'MVV in attesa risposta dal destinatario',
  status: 'wait',
};

export const MvvStatiBozza: IStepPropsData[] = [
  { ...BozzaState, status: 'process' },
  { title: 'Validazione', description: 'Inviare al MIPAAF per validazione', status: 'wait' },
  { ...InAttesaDest },
];

export const MvvStatiPresentato: IStepPropsData[] = [
  { ...BozzaState },
  { title: 'In Validazione', description: 'Presentato: in attesa di validazione', status: 'process' },
  { ...InAttesaDest },
];

export const MvvStatiNonValidato: IStepPropsData[] = [
  { ...BozzaState },
  { title: 'Non Validato', description: 'Errore di validazione del documento', status: 'error' },
];

export const MvvStatiValidato: IStepPropsData[] = [{ ...BozzaState }, { ...ValidatoState }, { ...InAttesaDest, status: 'process' }];

export const MvvStatiAnnullato: IStepPropsData[] = [
  { ...BozzaState },
  { ...ValidatoState },
  { title: 'Annullato', description: `MVV annullato dall'utente`, status: 'error' },
];

export const MvvStatiAccettato: IStepPropsData[] = [
  { ...BozzaState },
  { ...ValidatoState },
  { title: 'Accettato', description: 'MVV accettato dal destinatario', status: 'finish' },
];

export const MvvStatiRespinto: IStepPropsData[] = [
  { ...BozzaState },
  { ...ValidatoState },
  { title: 'Respinto', description: 'MVV Respinto dal destinatario', status: 'finish' },
];

export const MvvStatiAccettatoConRev: IStepPropsData[] = [
  { ...BozzaState },
  { ...ValidatoState },
  { title: 'Accettato con Rev.', description: 'Accettato dal destinatario con revisione quantitativi', status: 'finish' },
];

export const mvveConstants = {
  /** MVV FETCH AND REFRESH ACTIONS */
  FETCH_MVVE_REQ: 'FETCH_MVVE_REQ',
  FETCH_MVVE_OK: 'FETCH_MVVE_OK',
  FETCH_MVVE_KO: 'FETCH_MVVE_KO',
  EDIT_MVVE: 'EDIT_MVVE',
  REFRESH_MVVE_REQ: 'REFRESH_MVVE_REQ',
  REFRESH_MVVE_OK: 'REFRESH_MVVE_OK',
  REFRESH_MVVE_KO: 'REFRESH_MVVE_KO',
  RESET_SELECTED_MVVE: 'RESET_SELECTED_MVVE',

  /** MVV TOOLBAR ACTIONS */
  ANNULL_MVVE_REQ: 'ANNULL_MVVE_REQ',
  ANNULL_MVVE_OK: 'ANNULL_MVVE_OK',
  ANNULL_MVVE_KO: 'ANNULL_MVVE_KO',

  /** MVV PRODUCT ACTIONS */
  SELECT_MVV_PRODUCT: 'SELECT_MVV_PRODUCT',
  CREATE_MVV_PRODUCT: 'CREATE_MVV_PRODUCT',
  RESET_MVV_PRODUCT: 'RESET_MVV_PRODUCT',
  FETCH_CATEGORY_FROM_PRODUCT_TYPE_REQ: 'FETCH_CATEGORY_FROM_PRODUCT_TYPE_REQ',
  FETCH_CATEGORY_FROM_PRODUCT_TYPE_OK: 'FETCH_CATEGORY_FROM_PRODUCT_TYPE_OK',
  FETCH_CATEGORY_FROM_PRODUCT_TYPE_KO: 'FETCH_CATEGORY_FROM_PRODUCT_TYPE_KO',

  /** MVV FLOW => OPERATION WITH MIPAAF */
  SYNC_MVVE_REQ: 'SYNC_MVVE_REQ',
  SYNC_MVVE_OK: 'SYNC_MVVE_OK',
  SYNC_MVVE_KO: 'SYNC_MVVE_KO',

  VALIDATE_MVVE_REQ: 'VALIDATE_MVVE_REQ',
  VALIDATE_MVVE_OK: 'VALIDATE_MVVE_OK',
  VALIDATE_MVVE_KO: 'VALIDATE_MVVE_KO',

  DELETE_MVVE_REQ: 'DELETE_MVVE_REQ',
  DELETE_MVVE_OK: 'DELETE_MVVE_OK',
  DELETE_MVVE_KO: 'DELETE_MVVE_KO',

  SET_FORM_MODIFIED: 'SET_FORM_MODIFIED',
  REPLY_RECEIVED_MVV_REQ: 'REPLY_RECEIVED_MVV_REQ',
  REPLY_RECEIVED_MVV_OK: 'REPLY_RECEIVED_MVV_OK',
  REPLY_RECEIVED_MVV_KO: 'REPLY_RECEIVED_MVV_KO',

  UPDATE_NOTE_MVV_REQ: 'UPDATE_NOTE_MVV_REQ',
  UPDATE_NOTE_MVV_OK: 'UPDATE_NOTE_MVV_OK',
  UPDATE_NOTE_MVV_KO: 'UPDATE_NOTE_MVV_KO',

  SAVE_MVVE_REDUX: 'SAVE_MVVE_REDUX',
  SAVE_FORM_REDUX: 'SAVE_FORM_REDUX',

  DELETE_MESSAGE_ERROR: 'DELETE_MESSAGE_ERROR',
};

export const mvvCommonDatas = {
  statiMVVE: [
    { id: '01', desc: 'Bozza', shortDesc: 'Bozza', color: 'grey', icon: 'snippets', htmlColor: '#314659' },
    {
      id: '02',
      desc: 'Presentato: in attesa di validazione',
      shortDesc: 'In validazione',
      color: 'blue',
      icon: 'highlight',
      htmlColor: '#1890ff',
    },
    { id: '03', desc: 'Validato', shortDesc: 'Validato', color: 'blue', icon: 'check', htmlColor: '#1890ff' },
    { id: '04', desc: 'Non validato', shortDesc: 'Non validato', color: 'red', icon: 'close-square', htmlColor: '#f5222d' },
    { id: '05', desc: 'Annullato', shortDesc: 'Annullato', color: 'red', icon: 'exclamation-circle', htmlColor: '#f5222d' },
    { id: '06', desc: 'Accettato dal destinatario', shortDesc: 'Accettato', color: 'green', icon: 'like', htmlColor: '#52c41a' },
    { id: '07', desc: 'Respinto dal destinatario', shortDesc: 'Respinto', color: 'red', icon: 'dislike', htmlColor: '#f5222d' },
    {
      id: '08',
      desc: 'Accettato con revisione quantitativi',
      shortDesc: 'Accettato rev. Qt.',
      color: 'green',
      icon: 'gold',
      htmlColor: '#52c41a',
    },
  ] as Array<IStatoMVVE>,
};
