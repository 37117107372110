/** Backend base url */
export const webApiBaseUrl = `${process.env.REACT_APP_WEBAPI_BASEURL}/api/${process.env.REACT_APP_WEBAPI_BASE_VERSION}`;
export const webApiFormSchemaBaseUrl = `${process.env.REACT_APP_WEBAPI_FORM_SCHEMA_BASEURL}/api/${process.env.REACT_APP_WEBAPI_FORM_SCHEMA_BASE_VERSION}`;
export const webApiRegistryBaseUrl = `${process.env.REACT_APP_WEBAPI_REGISTRY_BASEURL}/api/${process.env.REACT_APP_WEBAPI_REGISTRY_BASE_VERSION}`;
export const webApiPraEnoBaseUrl = `${process.env.REACT_APP_WEBAPI_PRA_ENO_BASEURL}/api/${process.env.REACT_APP_WEBAPI_PRA_ENO_BASE_VERSION}`;

/** Application constants */
export const AUTOCOMPLETE_DEFAULT_DELAY = 500;
export const FORMAT_DATE = 'DD/MM/YYYY';
export const ITALY_CODE = 86;

/** Local storage constants */
export const localStorageTokenKey = 'REACT_TOKEN_AUTH_KEY';
export const localStorageTSDigitalData = 'TS_DIGITAL_PORTAL_DATA';

/** HTTP Header constants */
export const webApiHeaderFilterKey = 'filter';
export const XHeaderForceDeleteKey = 'X-Client-Delete-Confirmation';

/* TS Digital Portal URL */
export const tsDigitalPortalUrl = `${process.env.REACT_APP_TS_DIGITA_PORTAL_URL}`;

/** License Activable Modules */
export enum ActivableModules {
  MVV = 0x1,
  REG = 0x2,
  PEN = 0x4,
  MVV_REG = MVV | REG,
  PEN_MVV = PEN | MVV,
  PEN_REG = PEN | REG,
  MVV_REG_PEN = PEN | REG | MVV,
}
